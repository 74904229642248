const environments = {
    production: {
        DEPLOY_ENV: JSON.stringify('production'),
        GOOGLE_MAP_PARAMS: {
            v: JSON.stringify('3.exp'),
            key: JSON.stringify('AIzaSyC4ELGDS83CB9Lt-HWDSZkGThUoc0rw9j0'),
        },
        INTERCOM_ID: JSON.stringify('zs9927ce'),
        MIXPANEL_ID: JSON.stringify('3f10d076cd9e25450a4a09261321b49f'),
        GOOGLE_TAG_MANAGER_ID: JSON.stringify('GTM-W639ZCN'),
        GOOGLE_TAG_ID: JSON.stringify('G-E677DQYN3T'),
        GOOGLE_STORAGE_BUCKET: JSON.stringify('chefhero-storage'),
        GOOGLE_STORAGE_BUCKET_URL: JSON.stringify('https://storage.googleapis.com/chefhero-storage/static/fe-buyer-v2/'),
        GOOGLE_RECAPTCHA_KEY: JSON.stringify('6Ld_n-4bAAAAACBZ7tdZ-b8xBkgHhk6vpF5eV5_D'),
        SEGMENT_ID: JSON.stringify('GxOiTua0cmUTp2qiOgKov9QqXXUooUZp'),
        LOGROCKET_APP_ID: JSON.stringify('so76xw/chefhero-production'),
        NOTCH_DOMAIN: JSON.stringify('notchordering.com'),
        CLIENT_SIDE_ID: JSON.stringify('61dca5f0d381711523076166'),
        ADYEN_CLIENT_KEY: JSON.stringify('live_YYL3RZB7URGA5M5IUPKVLBWD3QUS42J6'),
        ADYEN_ENVIRONMENT: JSON.stringify('live-us'),
        BASE_URLS: {
            baseUrl: JSON.stringify('https://buyer-api.notchordering.com'),
            baseUrlv3: JSON.stringify('https://api.notchordering.com/v3'),
            uploadInvoiceUrl: JSON.stringify('https://upload-files-z6s43ocala-uc.a.run.app/api/v4/files/buyer-invoices/'),
            chatUrl: JSON.stringify('https://chat-buyer.api.notchordering.com'),
            accessListMPChatUrl: JSON.stringify('https://storage.googleapis.com/chefhero-storage-no-cors/feature_flags/market_chat_allowlist.json'),
            bushwhackUrl: JSON.stringify('https://bushwhack.api.notchordering.com'),
            secretShopUrl: JSON.stringify('https://secret-shop.api.notchordering.com'),
            coreUrl: JSON.stringify('https://core.api.notchordering.com'),
            greevilsGreedApi: JSON.stringify('https://greevils-greed.api.notchordering.com'),
            falsePromiseApi: JSON.stringify('https://false-promise.api.notchordering.com'),
            integrationApi: JSON.stringify('https://be-integration-z6s43ocala-uc.a.run.app'),
            accountsReceivableApi: JSON.stringify('https://ar.api.notchordering.com'),
            gatewayApi: JSON.stringify('https://admin-api.notchordering.com'),
            paymentLinkUrl: JSON.stringify('https://pay.notchordering.com'),
        },
        FEATURE_FLAGS: {
            isDebug: JSON.stringify(false),
        },
        FIREBASE: {
            apiKey: JSON.stringify('AIzaSyCSAZwKyTWUtyRl6T6b4wEwIORlEY1ZiZ4'),
            authDomain: JSON.stringify('producehero-project.firebaseapp.com'),
            databaseURL: JSON.stringify('https://producehero-project.firebaseio.com'),
            projectId: JSON.stringify('producehero-project'),
            storageBucket: JSON.stringify('producehero-project.appspot.com'),
            messagingSenderId: JSON.stringify('182019323643'),
            appId: JSON.stringify('1:182019323643:web:bad8c6bc19fa5b57c7a25e'),
        },
        STRIPE_API_KEY: JSON.stringify('pk_live_51JpvnVKXxUwbfkU1Q0Ge0gVYL5Y7jWzUjXPW3MqoAhfn9ATMMbj1OyLJ0onmhE5kogSvJpFSQpF3G3OrWJrhj0sX00i0AJVWcB'),
        STRIPE_US_API_KEY: JSON.stringify('pk_live_51KZhKdJ9OS8nB3tTIVqZAGVtlBX4LVr59MbtY2b7CThZjK3XDA9T35TINP0PzSiU9AfKaE92yX8QqJIEkAhmA8AN00BO5X0FKG'),
        BUSHWHACK_API_KEY: JSON.stringify('SpxIl29vYwpRmOKfD4gUYmukLHuyfCIToPhuQrygGiXjz7jSJ7LZGQXPRLSed4TC'),
        AR_GTM_TAG_ID: JSON.stringify('G-P8V4L2VGE8'),
        CORE_API_GOOGLE_CLOUD_BUCKET: JSON.stringify('https://storage.googleapis.com/notch-core-api-old-production'),
        RUTTER_PUBLIC_KEY: JSON.stringify('dd7cfbcb-5498-4d0c-8637-705c35de04ec'),
        RUTTER_DEBUG: JSON.stringify(false),
        NOTCH_SUPPLIER_ID: JSON.stringify('a490219c-3e29-494b-a66c-fb472b7c8b15'),
        AR_SERVER_TOKENS: JSON.stringify('c33c7e6f-5388-4496-8f26-a3ec825f1def'),
        AMPLITUDE_PUBLIC_API_KEY: JSON.stringify('e598e138bf317a61486a598541a220af'),
        SEND_AMPLITUDE_EVENT: JSON.stringify(true),
        SURCHARGES_AND_RESTRICTIONS_LINK: JSON.stringify('about:blank'),
        DRAFT_INVOICE_EMAIL_ADDRESS: JSON.stringify('@invoices.notch.financial'),
        PAYMENT_PROCESSOR_ENCRYPTION_PEM: JSON.stringify('LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1Ga3dFd1lIS29aSXpqMENBUVlJS29aSXpqMERBUWNEUWdBRVlLNk91UnlEVVlrdVI5WERyYW9PTXhNVTI5YkcNCjd5ZXIxWVcxY3VuMEc2QTJPYWJKVkVwSGpmbWdLQXJpSUlkNUc3QjVSdWhFcXlJb0ZFRXh2ZGNFWUE9PQ0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t'),
    },
    staging: {
        DISPLAY_REDUX_LOGGER: false,
        NODE_ENV: JSON.stringify('development'),
        DEPLOY_ENV: JSON.stringify('staging'),
        GOOGLE_MAP_PARAMS: {
            v: JSON.stringify('3.exp'),
            key: JSON.stringify('AIzaSyC4ELGDS83CB9Lt-HWDSZkGThUoc0rw9j0'),
        },
        INTERCOM_ID: JSON.stringify('nep6by1o'),
        MIXPANEL_ID: JSON.stringify('21bc3dd68392ae5b1c35094e242f15c7'),
        GOOGLE_TAG_MANAGER_ID: JSON.stringify('GTM-TJC96JJ'),
        GOOGLE_TAG_ID: JSON.stringify('G-3KT5E6ZNGV'),
        GOOGLE_STORAGE_BUCKET: JSON.stringify('chefhero-storage-release'),
        GOOGLE_STORAGE_BUCKET_URL: JSON.stringify('https://storage.googleapis.com/chefhero-storage-release/static/fe-buyer-v2/'),
        GOOGLE_RECAPTCHA_KEY: JSON.stringify('6LfybuEbAAAAAGiyKSqokfXjOVLqnWPJZBo46WXU'),
        SEGMENT_ID: JSON.stringify('00XF2IU3II06PMlQcqIzd1QNZ6ShbBlj'),
        LOGROCKET_APP_ID: JSON.stringify('so76xw/chefhero-staging'),
        NOTCH_DOMAIN: JSON.stringify('staging.notchordering.dev'),
        CLIENT_SIDE_ID: JSON.stringify('61dca71c55909914f2773d5a'),
        ADYEN_CLIENT_KEY: JSON.stringify('test_SILEMXSQ3RHLDMHJZZ3ODMWE5ADF3A6Z'),
        ADYEN_ENVIRONMENT: JSON.stringify('test'),
        BASE_URLS: {
            baseUrl: JSON.stringify('https://buyer-api.staging.notchordering.dev'),
            baseUrlv3: JSON.stringify('https://api.staging.notchordering.dev/v3'),
            uploadInvoiceUrl: JSON.stringify('https://upload-files-staging-2ldjhmvjua-uc.a.run.app/api/v4/files/buyer-invoices/'),
            chatUrl: JSON.stringify('https://chat-buyer.api.staging.notchordering.dev'),
            accessListMPChatUrl: JSON.stringify('https://storage.googleapis.com/chefhero-storage-no-cors/feature_flags/staging_market_chat_allowlist.json'),
            bushwhackUrl: JSON.stringify('https://bushwhack.api.staging.notchordering.dev'),
            secretShopUrl: JSON.stringify('https://secret-shop.api.staging.notchordering.dev'),
            coreUrl: JSON.stringify('https://core.api.staging.notchordering.dev'),
            greevilsGreedApi: JSON.stringify('https://greevils-greed.api.staging.notchordering.dev'),
            falsePromiseApi: JSON.stringify('https://false-promise.api.staging.notchordering.dev'),
            integrationApi: JSON.stringify('https://be-integration-staging-2ldjhmvjua-uc.a.run.app'),
            accountsReceivableApi: JSON.stringify('https://ar.api.experimental.notchordering.dev'),
            gatewayApi: JSON.stringify('https://admin-api.staging.notchordering.dev'),
            paymentLinkUrl: JSON.stringify('https://pay.experimental.notchordering.dev'),
        },
        FEATURE_FLAGS: {
            isDebug: JSON.stringify(true),
        },
        FIREBASE: {
            apiKey: JSON.stringify('AIzaSyDUBBBgxl9qHwZI8SLHjKSaaoIhWyqBZlU'),
            authDomain: JSON.stringify('chefhero-release.firebaseapp.com'),
            databaseURL: JSON.stringify('https://chefhero-release.firebaseio.com'),
            projectId: JSON.stringify('chefhero-release'),
            storageBucket: JSON.stringify('chefhero-release.appspot.com'),
            messagingSenderId: JSON.stringify('136338530075'),
            appId: JSON.stringify('1:136338530075:web:11b31d18e8321a5af17dbe'),
        },
        STRIPE_API_KEY: JSON.stringify('pk_test_51JpvnVKXxUwbfkU1XOxMwYrDMtQ4ETTO8ieZfgwgLcJ4xp1baBAVJGgFZEyQ45nGwrgsICYyRfWr2M6rxGzJKInW00V18eGKzF'),
        STRIPE_US_API_KEY: JSON.stringify('pk_test_51KZhKdJ9OS8nB3tTanVGoO6uPInofl27VxQXOyp2NgOwRTxPkuJ7w5nZfOdDsAFKLspgqe4pqicEmQa9qMccR13o00kgymj26f'),
        BUSHWHACK_API_KEY: JSON.stringify('geSU2U5HewhOJKoBFsT0nRXDzF6g59m34xKg964NzoeNd6Q1e0tZzGpkbDWVvHc9'),
        AR_GTM_TAG_ID: JSON.stringify('G-1HPWQ4EDW7'),
        CORE_API_GOOGLE_CLOUD_BUCKET: JSON.stringify('https://storage.googleapis.com/notch-core-api-old-staging'),
        RUTTER_PUBLIC_KEY: JSON.stringify('dd7cfbcb-5498-4d0c-8637-705c35de04ec'),
        RUTTER_DEBUG: JSON.stringify(true),
        NOTCH_SUPPLIER_ID: JSON.stringify('5551b411-ab72-4dd2-9a47-e3b22857ea3d'),
        AR_SERVER_TOKENS: JSON.stringify('4817b50a-30ca-4fa1-8451-b446c45bd0e2'),
        AMPLITUDE_PUBLIC_API_KEY: JSON.stringify('f53de181c80a811ff084b9411437a1eb'),
        SEND_AMPLITUDE_EVENT: JSON.stringify(false),
        SURCHARGES_AND_RESTRICTIONS_LINK: JSON.stringify('about:blank'),
        DRAFT_INVOICE_EMAIL_ADDRESS: JSON.stringify('@parse.staging.notchordering.dev'),
        PAYMENT_PROCESSOR_ENCRYPTION_PEM: JSON.stringify('LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1Ga3dFd1lIS29aSXpqMENBUVlJS29aSXpqMERBUWNEUWdBRVlLNk91UnlEVVlrdVI5WERyYW9PTXhNVTI5YkcNCjd5ZXIxWVcxY3VuMEc2QTJPYWJKVkVwSGpmbWdLQXJpSUlkNUc3QjVSdWhFcXlJb0ZFRXh2ZGNFWUE9PQ0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t'),
    },
    experimental: {
        DISPLAY_REDUX_LOGGER: false,
        NODE_ENV: JSON.stringify('development'),
        DEPLOY_ENV: JSON.stringify('develop'),
        GOOGLE_MAP_PARAMS: {
            v: JSON.stringify('3.exp'),
            key: JSON.stringify('AIzaSyC4ELGDS83CB9Lt-HWDSZkGThUoc0rw9j0'),
        },
        INTERCOM_ID: JSON.stringify('nep6by1o'),
        MIXPANEL_ID: JSON.stringify('21bc3dd68392ae5b1c35094e242f15c7'),
        AMPLITUDE_ID: JSON.stringify('b7085ae53d13f0f3c4b5f1ad8153db7f'),
        GOOGLE_TAG_MANAGER_ID: JSON.stringify('GTM-TJC96JJ'),
        GOOGLE_TAG_ID: JSON.stringify('G-3KT5E6ZNGV'),
        GOOGLE_STORAGE_BUCKET: JSON.stringify('chefhero-storage-exp'),
        GOOGLE_STORAGE_BUCKET_URL: JSON.stringify('https://storage.googleapis.com/chefhero-storage-release/static/fe-buyer-v2/'),
        GOOGLE_RECAPTCHA_KEY: JSON.stringify('6LfybuEbAAAAAGiyKSqokfXjOVLqnWPJZBo46WXU'),
        SEGMENT_ID: JSON.stringify('00XF2IU3II06PMlQcqIzd1QNZ6ShbBlj'),
        LOGROCKET_APP_ID: JSON.stringify('so76xw/test'),
        RUTTER_DEBUG: JSON.stringify(true),
        NOTCH_DOMAIN: JSON.stringify('experimental.notchordering.dev'),
        CLIENT_SIDE_ID: JSON.stringify('61dca71c55909914f2773d5a'),
        ADYEN_CLIENT_KEY: JSON.stringify('test_SILEMXSQ3RHLDMHJZZ3ODMWE5ADF3A6Z'),
        ADYEN_ENVIRONMENT: JSON.stringify('test'),
        BASE_URLS: {
            baseUrl: JSON.stringify('https://buyer-api.experimental.notchordering.dev'),
            baseUrlv3: JSON.stringify('https://api.experimental.notchordering.dev/v3'),
            uploadInvoiceUrl: JSON.stringify('https://upload-files-experimental-2ldjhmvjua-uc.a.run.app/api/v4/files/buyer-invoices/'),
            chatUrl: JSON.stringify('https://chat-buyer.api.experimental.notchordering.dev'),
            accessListMPChatUrl: JSON.stringify('https://storage.googleapis.com/chefhero-storage-no-cors/feature_flags/staging_market_chat_allowlist.json'),
            bushwhackUrl: JSON.stringify('https://bushwhack.api.experimental.notchordering.dev'),
            secretShopUrl: JSON.stringify('https://secret-shop.api.experimental.notchordering.dev'),
            coreUrl: JSON.stringify('https://core.api.experimental.notchordering.dev'),
            greevilsGreedApi: JSON.stringify('https://greevils-greed.api.experimental.notchordering.dev'),
            falsePromiseApi: JSON.stringify('https://false-promise.api.experimental.notchordering.dev'),
            integrationApi: JSON.stringify('https://integration.api.experimental.notchordering.dev'),
            accountsReceivableApi: JSON.stringify('https://ar.api.experimental.notchordering.dev'),
            gatewayApi: JSON.stringify('https://admin-api.experimental.notchordering.dev'),
            paymentLinkUrl: JSON.stringify('https://pay.experimental.notchordering.dev'),
        },
        FEATURE_FLAGS: {
            isDebug: JSON.stringify(true),
        },
        FIREBASE: {
            apiKey: JSON.stringify('AIzaSyD3GZ7zDOZVx57Zhqnf2NlY_eiq5jgUUzs'),
            authDomain: JSON.stringify('chefhero-experimental.firebaseapp.com'),
            databaseURL: JSON.stringify(
                'https://chefhero-experimental.firebaseio.com'
            ),
            projectId: JSON.stringify('chefhero-experimental'),
            storageBucket: JSON.stringify('chefhero-experimental.appspot.com'),
            messagingSenderId: JSON.stringify('823787576485'),
            appId: JSON.stringify('1:823787576485:web:c10741bf6ebd1285a6414c'),
        },
        STRIPE_API_KEY: JSON.stringify('pk_test_51JpvnVKXxUwbfkU1XOxMwYrDMtQ4ETTO8ieZfgwgLcJ4xp1baBAVJGgFZEyQ45nGwrgsICYyRfWr2M6rxGzJKInW00V18eGKzF'),
        STRIPE_US_API_KEY: JSON.stringify('pk_test_51KZhKdJ9OS8nB3tTanVGoO6uPInofl27VxQXOyp2NgOwRTxPkuJ7w5nZfOdDsAFKLspgqe4pqicEmQa9qMccR13o00kgymj26f'),
        BUSHWHACK_API_KEY: JSON.stringify('dzdVRaf3pr9PVbSp6d1UUWeVzBDtrCOqH0AjTI1GCBvomzDmP6HuwCel2G4OU78h'),
        AR_GTM_TAG_ID: JSON.stringify('G-1HPWQ4EDW7'),
        CORE_API_GOOGLE_CLOUD_BUCKET: JSON.stringify('https://storage.googleapis.com/notch-core-api-old-experimental'),
        RUTTER_PUBLIC_KEY: JSON.stringify('dd7cfbcb-5498-4d0c-8637-705c35de04ec'),
        NOTCH_SUPPLIER_ID: JSON.stringify('5551b411-ab72-4dd2-9a47-e3b22857ea3d'),
        AR_SERVER_TOKENS: JSON.stringify('4817b50a-30ca-4fa1-8451-b446c45bd0e2'),
        AMPLITUDE_PUBLIC_API_KEY: JSON.stringify('f53de181c80a811ff084b9411437a1eb'),
        SEND_AMPLITUDE_EVENT: JSON.stringify(false),
        SURCHARGES_AND_RESTRICTIONS_LINK: JSON.stringify('about:blank'),
        DRAFT_INVOICE_EMAIL_ADDRESS: JSON.stringify('@parse.staging.notchordering.dev'),
        PAYMENT_PROCESSOR_ENCRYPTION_PEM: JSON.stringify('LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1Ga3dFd1lIS29aSXpqMENBUVlJS29aSXpqMERBUWNEUWdBRVlLNk91UnlEVVlrdVI5WERyYW9PTXhNVTI5YkcNCjd5ZXIxWVcxY3VuMEc2QTJPYWJKVkVwSGpmbWdLQXJpSUlkNUc3QjVSdWhFcXlJb0ZFRXh2ZGNFWUE9PQ0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t'),
    },
    develop: {
        DISPLAY_REDUX_LOGGER: false,
        NODE_ENV: JSON.stringify('development'),
        DEPLOY_ENV: JSON.stringify('develop'),
        GOOGLE_MAP_PARAMS: {
            v: JSON.stringify('3.exp'),
            key: JSON.stringify('AIzaSyC4ELGDS83CB9Lt-HWDSZkGThUoc0rw9j0'),
        },
        INTERCOM_ID: JSON.stringify('nep6by1o'),
        MIXPANEL_ID: JSON.stringify('21bc3dd68392ae5b1c35094e242f15c7'),
        GOOGLE_TAG_MANAGER_ID: JSON.stringify('GTM-TJC96JJ'),
        GOOGLE_TAG_ID: JSON.stringify('G-3KT5E6ZNGV'),
        GOOGLE_STORAGE_BUCKET: JSON.stringify('chefhero-storage-dev'),
        GOOGLE_RECAPTCHA_KEY: JSON.stringify('6LfybuEbAAAAAGiyKSqokfXjOVLqnWPJZBo46WXU'),
        SEGMENT_ID: JSON.stringify('00XF2IU3II06PMlQcqIzd1QNZ6ShbBlj'),
        LOGROCKET_APP_ID: JSON.stringify('so76xw/test'),
        RUTTER_DEBUG: JSON.stringify(true),
        NOTCH_DOMAIN: JSON.stringify('notchordering.dev'),
        CLIENT_SIDE_ID: JSON.stringify('61dca5f0d381711523076165'),
        ADYEN_ENVIRONMENT: JSON.stringify('test'),
        ADYEN_CLIENT_KEY: JSON.stringify('test_SILEMXSQ3RHLDMHJZZ3ODMWE5ADF3A6Z'),
        BASE_URLS: {
            baseUrl: JSON.stringify('https://buyer-api.develop.notchordering.dev'),
            baseUrlv3: JSON.stringify('https://api.develop.notchordering.dev/v3'),
            uploadInvoiceUrl: JSON.stringify('https://upload-files-staging-2ldjhmvjua-uc.a.run.app/api/v4/files/buyer-invoices/'),
            chatUrl: JSON.stringify('https://be-chat-experimental-2ldjhmvjua-uc.a.run.app'),
            accessListMPChatUrl: JSON.stringify('https://storage.googleapis.com/chefhero-storage-no-cors/feature_flags/staging_market_chat_allowlist.json'),
            bushwhackUrl: JSON.stringify('https://bushwhack.api.staging.notchordering.dev'),
            secretShopUrl: JSON.stringify('https://secret-shop.api.staging.notchordering.dev'),
            coreUrl: JSON.stringify('https://core.api.staging.notchordering.dev'),
            paymentLinkUrl: JSON.stringify('https://pay.experimental.notchordering.dev'),
        },
        STRIPE_API_KEY: JSON.stringify('pk_test_51JpvnVKXxUwbfkU1XOxMwYrDMtQ4ETTO8ieZfgwgLcJ4xp1baBAVJGgFZEyQ45nGwrgsICYyRfWr2M6rxGzJKInW00V18eGKzF'),
        NOTCH_SUPPLIER_ID: JSON.stringify('5551b411-ab72-4dd2-9a47-e3b22857ea3d'),
        AMPLITUDE_PUBLIC_API_KEY: JSON.stringify('f53de181c80a811ff084b9411437a1eb'),
        SEND_AMPLITUDE_EVENT: JSON.stringify(false),
        SURCHARGES_AND_RESTRICTIONS_LINK: JSON.stringify('about:blank'),
    },
    local: {
        DISPLAY_REDUX_LOGGER: false,
        NODE_ENV: JSON.stringify('development'),
        DEPLOY_ENV: JSON.stringify('development'),
        GOOGLE_MAP_PARAMS: {
            v: JSON.stringify('3.exp'),
            key: JSON.stringify('AIzaSyC4ELGDS83CB9Lt-HWDSZkGThUoc0rw9j0'),
        },
        INTERCOM_ID: JSON.stringify('nep6by1o'),
        MIXPANEL_ID: JSON.stringify('21bc3dd68392ae5b1c35094e242f15c7'),
        GOOGLE_TAG_MANAGER_ID: JSON.stringify('GTM-TJC96JJ'),
        GOOGLE_TAG_ID: JSON.stringify('G-3KT5E6ZNGV'),
        GOOGLE_STORAGE_BUCKET: JSON.stringify('chefhero-storage-dev'),
        GOOGLE_RECAPTCHA_KEY: JSON.stringify('6LfybuEbAAAAAGiyKSqokfXjOVLqnWPJZBo46WXU'),
        SEGMENT_ID: JSON.stringify('00XF2IU3II06PMlQcqIzd1QNZ6ShbBlj'),
        LOGROCKET_APP_ID: JSON.stringify('so76xw/test'),
        NOTCH_DOMAIN: JSON.stringify('notchordering.dev'),
        CLIENT_SIDE_ID: JSON.stringify('62681d2187b83e152b4e5cde'),
        ADYEN_CLIENT_KEY: JSON.stringify('test_SILEMXSQ3RHLDMHJZZ3ODMWE5ADF3A6Z'),
        BASE_URLS: {
            baseUrl: JSON.stringify('http://localhost:8080'),
            baseUrlv3: JSON.stringify('http://localhost:8080/v3'),
            uploadInvoiceUrl: JSON.stringify('https://upload-files-staging-2ldjhmvjua-uc.a.run.app/api/v4/files/buyer-invoices/'),
            chatUrl: JSON.stringify('https://chat-buyer.api.staging.notchordering.dev'),
            accessListMPChatUrl: JSON.stringify('https://storage.googleapis.com/chefhero-storage-no-cors/feature_flags/staging_market_chat_allowlist.json'),
            bushwhackUrl: JSON.stringify('https://bushwhack.api.experimental.notchordering.dev'),
            secretShopUrl: JSON.stringify('https://secret-shop.api.experimental.notchordering.dev'),
            coreUrl: JSON.stringify('http://127.0.0.1:3000'),
            gatewayApi: JSON.stringify('https://admin-api.experimental.notchordering.dev'),
            paymentLinkUrl: JSON.stringify('https://pay.experimental.notchordering.dev'),
        },
        FEATURE_FLAGS: {
            isDebug: JSON.stringify(true),
        },
        FIREBASE: {
            apiKey: JSON.stringify('AIzaSyD3GZ7zDOZVx57Zhqnf2NlY_eiq5jgUUzs'),
            authDomain: JSON.stringify('chefhero-experimental.firebaseapp.com'),
            databaseURL: JSON.stringify(
                'https://chefhero-experimental.firebaseio.com'
            ),
            projectId: JSON.stringify('chefhero-experimental'),
            storageBucket: JSON.stringify('chefhero-experimental.appspot.com'),
            messagingSenderId: JSON.stringify('823787576485'),
            appId: JSON.stringify('1:823787576485:web:c10741bf6ebd1285a6414c'),
        },
        STRIPE_API_KEY: JSON.stringify('pk_test_51JpvnVKXxUwbfkU1XOxMwYrDMtQ4ETTO8ieZfgwgLcJ4xp1baBAVJGgFZEyQ45nGwrgsICYyRfWr2M6rxGzJKInW00V18eGKzF'),
        BUSHWHACK_API_KEY: JSON.stringify('dzdVRaf3pr9PVbSp6d1UUWeVzBDtrCOqH0AjTI1GCBvomzDmP6HuwCel2G4OU78h'),
        AR_GTM_TAG_ID: JSON.stringify('G-1HPWQ4EDW7'),
        CORE_API_GOOGLE_CLOUD_BUCKET: JSON.stringify('https://storage.googleapis.com/notch-core-api-old-staging'),
        RUTTER_PUBLIC_KEY: JSON.stringify('sandbox_pk_8ac40214-0dea-43fc-9b3b-26706a4276dd'),
        RUTTER_DEBUG: JSON.stringify(true),
        NOTCH_SUPPLIER_ID: JSON.stringify('5551b411-ab72-4dd2-9a47-e3b22857ea3d'),
        AMPLITUDE_PUBLIC_API_KEY: JSON.stringify('f53de181c80a811ff084b9411437a1eb'),
        SEND_AMPLITUDE_EVENT: JSON.stringify(false),
        SURCHARGES_AND_RESTRICTIONS_LINK: JSON.stringify('about:blank'),
        PAYMENT_PROCESSOR_ENCRYPTION_PEM: JSON.stringify('LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1Ga3dFd1lIS29aSXpqMENBUVlJS29aSXpqMERBUWNEUWdBRVlLNk91UnlEVVlrdVI5WERyYW9PTXhNVTI5YkcNCjd5ZXIxWVcxY3VuMEc2QTJPYWJKVkVwSGpmbWdLQXJpSUlkNUc3QjVSdWhFcXlJb0ZFRXh2ZGNFWUE9PQ0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t'),
    },
};

module.exports.storage = {
    experimental: '-exp',
    production: '',
    staging: '-release',
};

module.exports.environments = environments;
