import '../../../styles/dataPage.scss';
import React, { useEffect, useMemo } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Banner, Button, EBannerType, Input, Loading, NotchDataGrid, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { APRoutePaths, ARRoutePaths } from '@v2/constants/EPaths';
import BackIcon from '@icons/back-icon.svg';
import SearchIcon from '@icons/search-icon.svg';
import { PageTitle } from '@ar/components/PageTitle';

import { ApBill,
    OptionalAddress,
    ApBillItem,
    PatchApBillBody,
    PatchApBillLineItemData,
    Transaction,
    publishApBill,
    republishApBill,
    ApBillStatus } from '@ar/network/AccountsReceivable.network';
import { SeparatorInternalPageFullWidth } from '@ar/components/shared/SeparatorInternalPageFullWidth';
import { SectionTitle } from '@ar/components/shared/SectionTitle';
import { apBillValidationSchema, EmptyState, getBillItemColumns, gridSX } from '@ar/pages/ApBillDetailsPage/ApBillDetailsPageConstants';
import { useGetCustomer } from '@ar/hooks/queries/CustomerQueries.hook';
// import { usePublishOrRepublishApBillMutation, useApBillUpdateMutation } from '@ar/hooks/mutations/ApBillMutations';
import { getGpoId } from '@v2/utils/GPOUtils';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { DatePickerInput } from '@v2/components/Shared/DatePickerInput/DatePickerInput';
import AddIcon from '@icons/add-icon.svg';
import { CurrencyInput } from '@v2/components/Shared/InputCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApBillActionButton } from '@ar/pages/ApBillDetailsPage/ApBillActionButton';
import CheckIcon from '@icons/check-icon.svg';
import { ApBillStatusBadge } from '@ar/components/Invoices/ApBillStatusBadge';
import { centsToDollars, dollarsToCents, formatAsCurrency } from '@/utils/Formatters';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { CopyButton, CopyButtonFeedback } from '@/ar/components/shared/CopyButton';

import { ApBillModals, useApBillStore } from '@/ar/stores/ApBillStore';
import { ApBillItemDeleteModal } from '@/ar/components/ApBillDetails/ApBillItemDeleteModal';

import { useGetApBill } from '@/ar/hooks/queries/ApBillsQueries.hook';
import { CustomerOption, ApBillCustomerSearch } from './ApBillCustomerSearch';
import { ApBillCustomerAddress } from './ApBillCustomerAddress';
import { useKeyCombination } from '@/ar/hooks/useKeyCombination';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { ApBillProcessingModal } from '@/ar/components/ApBillDetails/ApBillProcessingModal';
import { useApBillUpdateMutation, usePublishOrRepublishApBillMutation } from '@/ar/hooks/mutations/ApBillMutations';
import { InvoiceAttachments } from '@/ar/components/shared/InvoiceAttachments';
import { ApBillItemFormModal } from '@/ar/components/ApBillDetails/ApBillItemFormModal';
import { ApBillDuplicateBillNumberConfirmModal } from '@/ar/components/ApBillDetails/ApBillDuplicateInvoiceNumberConfirmModal';

type CustomerInfo = {
    name: string,
    address: OptionalAddress,
};

const DEFAULT_CUSTOMER_INFO: CustomerInfo = {
    name: '',
    address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postal_code: ''
    }
};

type ApBillFormData = {
    customer_id?: string | null,
    bill_number?: string | null,
    po_number?: string | null,
    bill_date?: string | null,
    due_date?: string | null,
    bill_total?: number | null,
    offline_credits_payment?: number | null,
    outstanding_balance?: number | null,
    line_items?: ApBillItem[],
};

const calculateBillTotalsByItems = (items: ApBillItem[] = []) => {
    const subtotal = items.reduce((acc, item) => acc + (item.price * item.quantity), 0);
    const taxAmount = items.reduce((acc, item) => acc + item.tax_amount, 0);
    const total = subtotal + taxAmount;
    return {
        subtotal: centsToDollars(subtotal),
        taxAmount: centsToDollars(taxAmount),
        total: centsToDollars(total)
    };
};

const mapLineItemsToPatchBody = (lineItems: ApBillItem[]): PatchApBillLineItemData[] => {
    if (!lineItems || lineItems.length === 0) return [];

    return lineItems.map((lineItem) => ({
        id: lineItem.id,
        description: lineItem.description,
        price: lineItem.price,
        quantity: lineItem.quantity,
        tax_amount: lineItem.tax_amount,
        total: lineItem.total,
    }));
};

const valueFormatters = {
    bill_total: dollarsToCents,
    offline_credits_payment: dollarsToCents,
    outstanding_balance: dollarsToCents,
    line_items: mapLineItemsToPatchBody,
};

const mapUpdatedFieldsToPatchBody = (updatedFields: Partial<ApBillFormData>): Partial<PatchApBillBody> => (
    Object.entries(updatedFields).reduce((acc, [billField, value]) => {
        if (value !== undefined) {
            if (valueFormatters[billField]) {
                acc[billField] = valueFormatters[billField](value);
            } else {
                acc[billField] = value;
            }
        }

        return acc;
    }, {})
);

const areLineItemsEqual = (lineItemA: ApBillItem, lineItemB: ApBillItem) => {
    const keysToCompare = ['total', 'price', 'quantity', 'tax_amount', 'description', 'id'];

    return keysToCompare.every((key) => lineItemA[key] === lineItemB[key]);
};

const areLineItemsChanged = (originalLineItems: ApBillItem[], lineItems: ApBillItem[]): boolean => {
    if (!originalLineItems && !lineItems) return false;

    if (originalLineItems?.length !== lineItems?.length) return true;

    return lineItems?.some((lineItem) => {
        const originalLineItem = originalLineItems.find((item) => item.id === lineItem.id);
        return !areLineItemsEqual(originalLineItem, lineItem);
    }) ?? false;
};

const filterUpdatedFields = (originalBill: ApBillFormData, apBill: ApBillFormData): Partial<ApBillFormData> => {
    const keysToCompare = Object.keys(originalBill).filter((key) => key !== 'line_items');

    const updatedFields: Partial<ApBillFormData> = keysToCompare.reduce((acc, key) => {
        if (originalBill[key] !== apBill[key]) {
            acc[key] = apBill[key];
        }
        return acc;
    }, {});

    if (originalBill?.line_items && areLineItemsChanged(originalBill?.line_items, apBill?.line_items)) {
        updatedFields.line_items = apBill.line_items;
    }

    return updatedFields;
};

/**
 * TODO items
 * - Replace Customer with Vendor
 * - Publish/Republish functionality to convert to a bill
 * - Add new fields to the form if needed based on feedback
 */

/**
 * The AP Bills detail page
 *
 * @returns JSX Element
 */
export const ApBillDetailsPage = function ApBillDetailsPage(): JSX.Element {
    useDocumentTitle('Bill Details - Notch');
    const navigate = useNavigate();
    const flags = useFlags<FeatureFlags>();
    const { supplierLoginData } = useSupplierStore();
    const { openModal } = useApBillStore();
    const gpoId = getGpoId(supplierLoginData);
    const { billID } = useParams();
    console.log(billID, 'billID');

    const [showCustomerSearchInput, setShowCustomerSearchInput] = React.useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = React.useState<CustomerInfo>(DEFAULT_CUSTOMER_INFO);
    const [existingBillId, setExistingBillId] = React.useState<string>(null);
    const apBillUpdateMutation = useApBillUpdateMutation();
    const isSaving = apBillUpdateMutation.isLoading;
    const lineItemsFeatureFlagEnabled = flags.apBillEditorLineItems;

    const mapBillValuesToForm = (bill: ApBill): Partial<ApBill> => ({
        customer_id: bill.customer_id,
        bill_number: bill.bill_number,
        bill_date: bill?.bill_date ? new Date(bill?.bill_date).toISOString().split('T')[0] : null,
        due_date: bill?.due_date ? new Date(bill?.due_date).toISOString().split('T')[0] : null,
        bill_total: centsToDollars(bill?.bill_total ?? 0),
        offline_credits_payment: centsToDollars(bill?.offline_credits_payment ?? 0),
        outstanding_balance: centsToDollars(bill?.outstanding_balance ?? 0),
        line_items: lineItemsFeatureFlagEnabled ? bill.line_items : [],
        supplier_id: bill.supplier_id ?? supplierLoginData?.supplier_id,
        external_id: bill.external_id
    });

    const publishApBillMutation = usePublishOrRepublishApBillMutation({
        mutationFn: publishApBill,
        successMessage: 'Bill published successfully',
    });

    const republishBillMutation = usePublishOrRepublishApBillMutation({
        mutationFn: republishApBill,
        successMessage: 'Bill republished successfully',
    });

    const isPublishing = publishApBillMutation.isLoading || republishBillMutation.isLoading;

    const { data: bill, isLoading, isError: isErrorBill } = useGetApBill(
        {
            customerID: supplierLoginData?.customer_id,
            apBillID: billID,
        },
        // this means that the data will be cached forever or only loaded once and never refetched
        {
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );

    const canPublishApBill = bill?.status === ApBillStatus.Draft;
    const canSaveApBill = bill?.status === ApBillStatus.Draft;
    const isProcessing = bill?.status === ApBillStatus.Processing;
    const isEditingApBill = bill?.status === ApBillStatus.Draft && !!bill.transaction_id;

    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm<ApBill>({
        mode: 'all',
        resolver: zodResolver(apBillValidationSchema),
    });

    const { errors, defaultValues } = useFormState({
        control,
    });

    const validateApBillForDuplicatedBillNumber = async (): Promise<Transaction | undefined> => undefined;
    // const validateApBillForDuplicatedBillNumber = async (billNumber: string): Promise<Transaction | undefined> => undefined;
    // const transactionsResponse = await getTransactions({
    //     bill_number: billNumber,
    //     supplier_id: supplierLoginData?.supplier_id,
    //     gpo_id: gpoId,
    //     offset: 0,
    //     limit: 1,
    // });

    // return transactionsResponse?.transactions?.find((transaction) => transaction.draft_bill_id !== bill.id);

    useEffect(() => {
        if (!bill) return;

        reset(mapBillValuesToForm(bill));

        if (bill.customer_id && bill?.customer?.businessName) {
            setCustomerInfo({
                name: bill.customer?.businessName ?? '',
                address: {
                    line1: bill.customer?.line1,
                    line2: bill.customer?.line2,
                    city: bill.customer?.city,
                    state: bill.customer?.state,
                    postal_code: bill.customer?.postalCode,
                }
            });
            setShowCustomerSearchInput(false);
        } else {
            setCustomerInfo(DEFAULT_CUSTOMER_INFO);
        }

        const checkForDuplicateBillNumber = async () => {
            const existingBill = await validateApBillForDuplicatedBillNumber();
            // const existingBill = await validateApBillForDuplicatedBillNumber(bill.bill_number);

            if (existingBill) {
                setExistingBillId(existingBill.id);
            }
        };

        if (bill.bill_number) {
            checkForDuplicateBillNumber();
        }
    }, [bill]);

    const billNumber = watch('bill_number');
    const customerId = watch('customer_id');
    const total = watch('bill_total');
    const paidOffline = watch('offline_credits_payment');
    // these are the line items that are being watched from the form and preloaded from the bill
    const lineItems = watch('line_items');
    const showWarningBanner = existingBillId && bill.status === ApBillStatus.Draft && bill?.bill_number.toLowerCase() === billNumber.toLowerCase();

    const { data: customerResults, isFetching: isFetchingCustomer } = useGetCustomer({
        customerID: customerId,
        supplierID: supplierLoginData?.supplier_id,
        gpoId,
        supplierIdFilter: bill?.supplier_id
    });

    const { subtotal, taxAmount } = useMemo(
        () => calculateBillTotalsByItems(lineItems),
        [lineItems]
    );

    useEffect(() => {
        const outstandingBalance = total - paidOffline;
        setValue('outstanding_balance', outstandingBalance);
    }, [total, paidOffline]);

    const handleLineItemsTotalCalculation = (): void => {
        const newTotal = calculateBillTotalsByItems(lineItems).total;
        setValue('bill_total', newTotal);
    };

    useEffect(() => {
        if (!lineItems) return;

        if (lineItems.length === 0 && defaultValues?.line_items?.length === 0) {
            setValue('bill_total', centsToDollars(bill?.bill_total));
        } else {
            handleLineItemsTotalCalculation();
        }
    }, [lineItems, defaultValues?.line_items]);

    useEffect(() => {
        if (customerResults?.id) {
            setCustomerInfo({
                name: customerResults.name,
                address: customerResults.address
            });
        }
    }, [customerResults?.id]);

    const handleOnMutateItem = (data: ApBillItem, isNewItem: boolean): void => {
        if (isNewItem) {
            setValue('line_items', [...(lineItems ?? []), data]);
        } else {
            const updatedItems = lineItems.map((item) => {
                if (item.id === data.id) {
                    return data;
                }
                return item;
            });
            setValue('line_items', updatedItems);
        }
    };

    const handleOnDeleteItem = (itemId: string) => {
        const updatedItems = lineItems.filter((lineItem) => lineItem.id !== itemId);
        setValue('line_items', updatedItems);
    };

    const handleBackButton = (): void => {
        const { history } = window;

        if (history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/${APRoutePaths.AP_BILLS}`);
        }
    };

    const handleEditCustomerClick = (): void => {
        setShowCustomerSearchInput(true);
    };

    const handleCustomerSelect = (field, selectedCustomer: CustomerOption): void => {
        field.onChange(selectedCustomer.id);
        setCustomerInfo({
            name: selectedCustomer.name,
            address: selectedCustomer.address
        });
        setValue('customer_id', selectedCustomer.id);
        setShowCustomerSearchInput(false);
    };

    const showCustomerSearchInputInitially = !customerId && !isFetchingCustomer;
    const hasItems = bill && lineItems?.length > 0;

    const handleSaveApBill = async () => {
        // Save draft bill without validation
        const data: ApBillFormData = getValues();
        const updatedFields = filterUpdatedFields(defaultValues as ApBillFormData, data);
        const payload = mapUpdatedFieldsToPatchBody(updatedFields);

        // temporary keeping for debug purposes
        // eslint-disable-next-line no-console
        console.log('Handle save', { data, defaultValues, updatedFields, payload });

        await apBillUpdateMutation.mutateAsync({
            apBillID: billID,
            customerID: supplierLoginData?.customer_id,
            body: payload,
        }, {
            onSuccess: async () => {
                toast.show({
                    message: 'Bill saved successfully',
                    icon: <CheckIcon/>,
                });
            },
            onError: () => {
                toast.show({
                    message: 'Failed to save draft bill',
                });
            }
        });
    };

    const publishBill = async () => {
        if (bill.transaction_id) {
            await republishBillMutation.mutateAsync({
                apBillID: billID,
                customerID: supplierLoginData?.customer_id,
            });
        } else {
            await publishApBillMutation.mutateAsync({
                apBillID: billID,
                customerID: supplierLoginData?.customer_id
            });
        }
    };

    const confirmPublishAndSave = async () => {
        await handleSaveApBill();
        await publishBill();
    };

    const handlePublishAndSave = async () => {
        if (!canPublishApBill) {
            return;
        }

        // const existingBill = await validateApBillForDuplicatedBillNumber(billNumber);
        const existingBill = await validateApBillForDuplicatedBillNumber();

        if (existingBill) {
            openModal(ApBillModals.DuplicateBillNumberConfirmModal, undefined, existingBill);
            return;
        }

        await confirmPublishAndSave();
    };

    const renderWarningBanner = () => (
        <Banner
            className="my-4 mr-8 border border-orange-200 rounded-md"
            alertType={EBannerType.WARNING}
            body={
                <Typography as="div" className="text-gray-600">
                    We&apos;ve noticed that an bill with{' '}
                    <Link target="_blank" to={`/${APRoutePaths.AP_BILLS}/${existingBillId}`} className="text-blue-500">this number</Link>{' '}
                    already exists in our system. You can save & publish this draft to push an update to the existing bill or archive this draft.
                </Typography>
            }
            isDismissable={false} />
    );

    // Save draft bill on Ctrl + S
    useKeyCombination(['s'], handleSaveApBill);
    useKeyCombination(['p'], handleSubmit(handlePublishAndSave));

    if (isLoading) {
        return <Loading isDark />;
    }

    if (isErrorBill) {
        return (
            <>
                <section className="flex gap-2 justify-between items-center  mb-6 ">
                    <Button className="flex items-center -ml-3.5 text-gray-600"
                        as="div"
                        size="SMALL"
                        variant="SUBDUED_ICON"
                        onClick={handleBackButton}>
                        <BackIcon className="w-4 h-4 mr-3"/>
                        Back
                    </Button>
                </section>
                <div className="flex flex-col gap-4 ">
                    <PageTitle>Bill not found</PageTitle>
                    <Typography as="div" className="text-gray-600">Apologies, no bill matching the given criteria could be found in the system.</Typography>
                </div>
            </>
        );
    }

    return (
        <>
            <section className="flex gap-2 justify-between items-center  mb-6 ">
                <Button className="flex items-center -ml-3.5 text-gray-600"
                    size="SMALL"
                    variant="SUBDUED_ICON"
                    onClick={handleBackButton}>
                    <BackIcon className="w-4 h-4 mr-3"/>
                    Back
                </Button>
                {!isProcessing && <div className="flex gap-3 items-center shrink-0">
                    {canSaveApBill && !isEditingApBill && <Button size="SMALL"
                        loading={isSaving}
                        onClick={handleSaveApBill}>
                                        Save
                    </Button>}
                    {/* This button won't save but publish the existing draft bill in the DB, this will only appear when an bill is in draft and linked to a transaction */}
                    {isEditingApBill && <Button size="SMALL"
                        disabled={isSaving || isPublishing}
                        loading={isPublishing}
                        onClick={publishBill}>
                                        Cancel
                    </Button>}
                    {canPublishApBill && <Button size="SMALL"
                        variant="TERTIARY_DARK"
                        loading={isPublishing}
                        // disabled={isPublishing || isSaving}
                        // TODO: enable when publish bill functionality is ready
                        disabled={true}
                        onClick={handleSubmit(handlePublishAndSave)}>
                        Save & {bill.transaction_id ? 'Republish' : 'Publish'}
                    </Button>}
                    <ApBillActionButton bill={bill} />

                </div>}

            </section>
            <form className="h-full flex flex-col" onSubmit={(e) => e.preventDefault()}>
                {/* <section className="pb-8 flex justify-between"> */}
                <section className="pb-8 flex justify-between flex-grow">
                    <div>
                        <div className="flex gap-3 items-center">
                            <PageTitle>{isProcessing ? 'New draft' : formatAsCurrency(centsToDollars(bill?.bill_total))}</PageTitle>
                            <ApBillStatusBadge status={bill.status as ApBillStatus}/>
                        </div>
                        {!!bill?.bill_number && <Typography className="text-gray-600 mt-1" as="div"><div className="flex gap-2 item-center">
                            <div>
                                {/* check if the bill is linked to a transaction and if it's in draft status */}
                                {isEditingApBill && <span className="text-gray-600">Editing</span> } <span className="text-gray-700">Bill #{bill.bill_number}</span></div>
                            <CopyButton buttonClassName="p-0"
                                textToCopy={bill.bill_number}
                                feedbackType={CopyButtonFeedback.TOAST}
                                successMessage="Bill number copied"
                                errorMessage="Error copying bill number to clipboard" />
                        </div>
                        </Typography>}
                    </div>

                </section>
                <SeparatorInternalPageFullWidth className="pb-px"/>
                <section className="flex lg:flex-row flex-col h-full relative">
                    <div className="lg:border-r lg:w-7/12 border-r-gray-200 w-full h-full">
                        {isLoading && <Loading isDark/>}
                        {!isLoading && bill && (<>
                            {showWarningBanner && renderWarningBanner()}
                            <SectionTitle className=" pt-6 mb-6">Details</SectionTitle>
                            <section className="pb-8 pr-8 flex flex-col xl:flex-row gap-6 w-full">

                                <div className="flex flex-col gap-4 customer-details w-full xl:w-7/12 h-full">

                                    <div className="flex flex-col gap-2 items-start w-full">
                                        <Typography className="m-0 h-[23px]">Customer</Typography>
                                        <div className="flex justify-between items-center w-full">
                                            {!customerInfo.name && isFetchingCustomer && <Loading isDark/>}
                                            {(showCustomerSearchInputInitially || showCustomerSearchInput) && (
                                                <Controller
                                                    name="customer_id"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ApBillCustomerSearch autoFocus={showCustomerSearchInput} onSelect={(selectedCustomer) => handleCustomerSelect(field, selectedCustomer)} />
                                                    )}/>
                                            )}
                                            {(customerInfo.name && !showCustomerSearchInput) && <>
                                                <Link className="text-teal-500" to={`/${ARRoutePaths.AR_CUSTOMERS}/${customerId}`}>{customerInfo.name}</Link>
                                                <Button variant="ICON" size="ICON_SMALL" onClick={handleEditCustomerClick}>
                                                    <SearchIcon className="w-4 h-4 text-gray-600" />
                                                </Button>
                                            </>}
                                        </div>
                                        {(!isFetchingCustomer || customerInfo.name) && <div>
                                            <Typography className="mt-1">Address</Typography>
                                            <ApBillCustomerAddress address={customerInfo.address}/>
                                        </div>}
                                        {errors.customer_id && <Typography variant="BASE" className="text-red-300 m-0 mt-auto">{errors.customer_id.message}</Typography>}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 w-full xl:w-5/12">
                                    <Controller
                                        name="bill_number"
                                        control={control}
                                        render={({ field }) => <Input
                                            label="Bill no."
                                            variant="SMALL"
                                            isInvalid={!!errors.bill_number}
                                            invalidMessage={errors.bill_number?.message}
                                            required
                                            inputProps={{
                                                ...field,
                                            }}/>}/>
                                    <div className="flex gap-4 items-center ">
                                        <div className="relative w-full h-full">
                                            <Controller
                                                name="bill_date"
                                                control={control}
                                                render={({ field }) => <DatePickerInput
                                                    inputFieldProps={{
                                                        isInvalid: !!errors.bill_date,
                                                        invalidMessage: errors.bill_date?.message,
                                                        inputClassName: 'pr-0'
                                                    }}
                                                    variant="SMALL"
                                                    required
                                                    label="Bill Date"
                                                    inputProps={{ readOnly: true }}
                                                    value={field.value}
                                                    onChange={(value): void => {
                                                        const utcDate = new Date(value).toISOString().split('T')[0];
                                                        return field.onChange(utcDate);
                                                    }}
                                                    id="bill_date"/>}/>
                                        </div>
                                        <div className="relative w-full h-full">
                                            <Controller
                                                name="due_date"
                                                control={control}
                                                render={({ field }) => <DatePickerInput
                                                    variant="SMALL"
                                                    required
                                                    label="Due Date"
                                                    inputProps={{ readOnly: true }}
                                                    inputFieldProps={{
                                                        isInvalid: !!errors.due_date,
                                                        invalidMessage: errors.due_date?.message,
                                                        inputClassName: 'pr-0'
                                                    }}
                                                    value={field.value}
                                                    onChange={(value): void => {
                                                        const utcDate = new Date(value).toISOString().split('T')[0];
                                                        return field.onChange(utcDate);
                                                    }}
                                                    id="due_date"/>}/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>)}
                        {lineItemsFeatureFlagEnabled && <>
                            <section className="py-8">
                                <SectionTitle className="flex items-center gap-2 ">Line items</SectionTitle>

                                {!hasItems && <EmptyState text="There are no items in this bill."/>}
                                {hasItems && <NotchDataGrid className="overflow-visible"
                                    columns={getBillItemColumns()}
                                    autoHeight={true}
                                    getRowHeight={() => 'auto'}
                                    rowCount={lineItems.length}
                                    sx={gridSX}
                                    sortingMode="client"
                                    onRowClick={({ row }) => openModal(ApBillModals.LineItem, row)}
                                    disableSelectionOnClick
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableColumnMenu
                                    rowsPerPageOptions={[50, 100]}
                                    hideFooter={true}
                                    rows={lineItems}
                                    loading={isLoading}/>}
                                <button className="bg-transparent mt-4"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        return openModal(ApBillModals.LineItem);
                                    }}>
                                    <Typography className="flex items-center font-medium cursor-pointer gap-2" >
                                        <AddIcon className="text-teal-500 w-4 h-4" />
                                        <span className="text-teal-500">Add line items</span>
                                    </Typography>
                                </button>
                                {hasItems && (
                                    <div className="flex justify-end mt-6">
                                        <div className="flex flex-col  space-y-3 lg:w-80">
                                            <Separator/>
                                            <div className="flex items-center space-x-4 justify-between">
                                                <Typography className="m-0">Subtotal:</Typography>
                                                <Typography className="m-0 text-right">{formatAsCurrency(subtotal)}</Typography>
                                            </div>
                                            <Separator/>
                                            <div className="flex items-center space-x-4 justify-between">
                                                <Typography className="m-0">Tax Amount:</Typography>
                                                <Typography className="m-0 text-right">{formatAsCurrency(taxAmount)}</Typography>
                                            </div>
                                            <Separator/>
                                        </div>

                                    </div>
                                )}
                            </section>
                            <Separator/>
                        </>}
                        <section>
                            <div className="py-8 pr-8 grid customer-details h-full">
                                <div className="flex flex-col gap-8 h-full">
                                    <div className="flex items-start gap-2">
                                        <Controller
                                            name="bill_total"
                                            control={control}
                                            render={({ field }) => <CurrencyInput
                                                required
                                                allowNegative
                                                isInvalid={!!errors.bill_total}
                                                invalidMessage={errors.bill_total?.message}
                                                variant="SMALL"
                                                disabled={hasItems}
                                                onChange={(e):void => {
                                                    field.onChange(e.floatValue);
                                                }}
                                                value={field.value}
                                                className="lg:flex lg:flex-row lg:justify-between lg:items-center"
                                                fontSize="[&>label]:text-4 [&>label]:lg:text-3 [&>label]:font-semibold font-semibold"

                                                label={'Total'}/>}/>
                                    </div>
                                    <Controller
                                        name="offline_credits_payment"
                                        control={control}
                                        render={({ field }) => <CurrencyInput
                                            required
                                            isInvalid={!!errors.offline_credits_payment}
                                            invalidMessage={errors.offline_credits_payment?.message}
                                            variant="SMALL"
                                            allowNegative
                                            onChange={(e):void => {
                                                field.onChange(e.floatValue);
                                            }}
                                            className="lg:flex lg:flex-row lg:justify-between lg:items-center"
                                            value={field.value}
                                            label={'Offline payments & credits'}/>}/>
                                    {/* TODO: this will be a new field coming from the DB and stored  */}
                                    <Controller
                                        name="outstanding_balance"
                                        control={control}
                                        render={({ field }) => <CurrencyInput
                                            required
                                            disabled={!hasItems}
                                            isInvalid={!!errors.outstanding_balance}
                                            invalidMessage={errors.outstanding_balance?.message}
                                            variant="SMALL"
                                            allowNegative
                                            onChange={(e):void => {
                                                field.onChange(e.floatValue);
                                            }}
                                            value={field.value}
                                            className="lg:flex lg:flex-row lg:justify-between lg:items-center"
                                            label={'Outstanding balance'}/>}/>

                                </div>
                            </div>
                        </section>
                        <InvoiceAttachments attachments={bill.attachments} showTitle showSeparator/>

                    </div>
                    <div className=" w-full lg:w-5/12">
                        <div className="flex flex-col gap-8 h-full">
                            {!!bill.bill_pdf_url && (
                                <iframe
                                    src={`${bill.bill_pdf_url}#toolbar=1&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="100%"
                                    title="Bill PDF"
                                    style={{ border: 'none' }}/>

                            )}
                        </div>
                        {errors.bill_pdf_url && <Typography className="text-red-500">{errors.bill_pdf_url.message}</Typography>}
                    </div>
                    {isProcessing && (<ApBillProcessingModal />)}
                </section>
            </form>

            <ApBillItemFormModal onItemSubmit={handleOnMutateItem} />
            <ApBillItemDeleteModal onConfirmDelete={handleOnDeleteItem} />
            <ApBillDuplicateBillNumberConfirmModal onConfirmSubmit={confirmPublishAndSave} apBillId={bill.id}/>
        </>
    );
};
