import React from 'react';
import WarningIcon from '@icons/warning-icon.svg';
import { ARRoutePaths } from '@v2/constants/EPaths';
import { Link } from 'react-router-dom';
import { Button, Loading, Modal, Separator, Typography, toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import InboxIcon from '@ar/assets/icons/inbox-icon.svg';
import { ApBillModals, useApBillStore } from '@/ar/stores/ApBillStore';
import { useApBillUpdateMutation } from '@/ar/hooks/mutations/ApBillMutations';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { ApBillStatus } from '@/ar/network/AccountsReceivable.network';

export type ApBillDuplicateBillNumberConfirmModalProps = {
    onConfirmSubmit: () => void,
    apBillId: string,
};

export const ApBillDuplicateBillNumberConfirmModal : React.FC<ApBillDuplicateBillNumberConfirmModalProps> = ({ onConfirmSubmit, apBillId }) => {
    const { supplierLoginData } = useSupplierStore();
    const { modals, closeModal } = useApBillStore();
    const apBillUpdateMutation = useApBillUpdateMutation();

    const { bill } = modals;

    const handleConfirmButtonClick = () => {
        onConfirmSubmit();
        closeModal(ApBillModals.DuplicateBillNumberConfirmModal);
    };

    const handleOnClose = () => {
        closeModal(ApBillModals.DuplicateBillNumberConfirmModal);
    };

    const handleArchiveDraftButtonClick = async () => {
        apBillUpdateMutation.mutate({
            apBillID: apBillId,
            customerID: supplierLoginData?.customer_id,
            body: {
                status: ApBillStatus.Archived,
            },
        }, {
            onSuccess: async () => {
                toast.show({
                    message: 'Bill has been archived',
                    icon: <CheckIcon/>,
                });
                handleOnClose();
            },
            onError: () => {
                toast.show({
                    message: 'Failed to archive draft bill',
                });
            }
        });
    };

    if (!bill) return null;

    return <Modal isOpen={modals[ApBillModals.DuplicateBillNumberConfirmModal]}
        onClose={handleOnClose}
        title={<div className="flex items-center gap-4">
            <WarningIcon className="w-8 h-8 text-orange-400 bg-orange-100 p-2 rounded" />
            <Typography className="m-0 font-medium" variant="LG-2">
                Existing bill detected
            </Typography>
        </div>}
        headerPadding="mt-0 mb-5"
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[400px]">
        <Typography as="div" className="px-5 pb-10 text-gray-700">
            We&apos;ve noticed that an bill with{' '}
            <Link target="_blank" to={`/${ARRoutePaths.AR_INVOICES}/${bill.id}`} className="text-blue-500">this number</Link>{' '}
            already exists in our system. You can save & publish this draft to push an update to the existing bill or archive this draft.
        </Typography>
        <Separator className="my-4"/>

        <div className="flex items-center gap-4 ml-auto w-full px-5">
            <Button
                disabled={apBillUpdateMutation.isLoading}
                onClick={handleArchiveDraftButtonClick}
                variant="TERTIARY_FILLED"
                size="SMALL"
                className="ml-auto"
                minWidth="w-auto">
                <div className="flex items-center gap-2">
                    {apBillUpdateMutation.isLoading ? <Loading className="ml-auto" isDark/> : <InboxIcon className="w-4 h-4 mt-0.5 "/>} Archive Draft
                </div>
            </Button>
            <Button
                disabled={apBillUpdateMutation.isLoading}
                onClick={handleConfirmButtonClick}
                variant="SECONDARY"
                size="SMALL"
                minWidth="w-auto">
                Save & update bill
            </Button>
        </div>
    </Modal>;
};
