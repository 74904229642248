import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';

import { SearchInput } from '@ar/components/shared/SearchInput/SearchInput';
import { ApBillStatusFilterButton } from './ApBillStatusFilterButton';
import { useApBillsStore } from '@/ar/stores/ApBillsStore';
import { InvoiceRangeDateFilterButton } from '../../Invoices/InvoiceFilters/InvoiceRangeDateFilterButton';

const DEBOUNCE_SEARCH_QUERY_DELAY = 500;

export const ApBillFilters : React.FC = () => {
    const { updateSearchParams, resetPagination, searchParams } = useApBillsStore();
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.bill_number ?? '');
    const debounceSearchQuery = useMemo(() => debounce((query:string) => {
        if (query === '') {
            updateSearchParams({ bill_number: undefined, page: 0 });
            return;
        }
        updateSearchParams({ bill_number: query, page: 0 });
        resetPagination();
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);
    const handleQueryChange = (query: string = ''):void => {
        setSearchQuery(query);
        debounceSearchQuery(query);
    };
    return <div className="flex flex-col gap-3 py-6">
        <SearchInput onChange={(value):void => handleQueryChange(value as string)}
            value={searchQuery}
            inputProps={{
                placeholder: 'Search for bill number',
            }
            }/>
        <div className="flex gap-2 flex-wrap">
            <ApBillStatusFilterButton/>
            {/* <ApBillCustomerFilterButton/> */}
            <InvoiceRangeDateFilterButton label={'Due Date'}
                initialEndDate={searchParams?.due_date_end ? new Date(searchParams?.due_date_end) : undefined}
                initialStartDate={searchParams?.due_date_start ? new Date(searchParams?.due_date_start) : undefined}
                onDateRangeChange={({ endDateUTC, startDateUTC }):void => {
                    const params = {
                        due_date_start: startDateUTC ?? undefined,
                        due_date_end: endDateUTC ?? undefined,
                        page: 0,
                    };
                    updateSearchParams(params);
                }}/>
            <InvoiceRangeDateFilterButton
                initialStartDate={searchParams?.bill_date_start ? new Date(searchParams?.bill_date_start) : undefined}
                initialEndDate={searchParams?.bill_date_end ? new Date(searchParams?.bill_date_end) : undefined}

                label={'Issue Date'}
                onDateRangeChange={({ startDateUTC, endDateUTC }):void => {
                    const params = {
                        bill_date_start: startDateUTC ?? undefined,
                        bill_date_end: endDateUTC ?? undefined,
                        page: 0,
                    };
                    updateSearchParams(params);
                }}/>

        </div>
    </div>;
};
