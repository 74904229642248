import { Button, Popover, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import EditPencilIcon from '@icons/edit-pencil-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { GetTransactionItem } from '@/ar/network/AccountsReceivable.network';
import { useApBillStore, ApBillModals } from '@/ar/stores/ApBillStore';

export interface ApBillItemsActionButtonColumnProps {
    item: GetTransactionItem,
}

export const ApBillItemsActionButtonColumn : React.FC<ApBillItemsActionButtonColumnProps> = ({ item }) => {
    const { openModal } = useApBillStore();
    return <div className="flex gap-1 ml-auto"
        onClick={(e): void => {
            e.preventDefault();
            e.stopPropagation();
        }}>
        <div>
            <Popover
                className="w-auto"
                button={<Button
                    variant="TERTIARY_TABLE"
                    className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                    size="SMALL"
                    stopPropagation={false}>
                    <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                        <EllipsisIcon className="h-4 w-4" />
                    </div>
                </Button>}
                items={[
                    {
                        label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                            <div className="w-4"><EditPencilIcon className="w-4 h-4 mt-0.5 " /></div>
                            <div className="flex flex-col truncate pr-5.5">
                                <div>Edit more details</div>
                            </div>
                        </Typography>,
                        className: 'z-50',
                        onClick: (): void => {
                            openModal(ApBillModals.LineItem, item);
                        },
                    },
                    {
                        label: <div className="flex text-red-500">
                            <TrashIcon className="w-4 h-4 mt-0.5 fill-red-500 mr-3" />
                            <Typography className="flex flex-nowrap gap-3 items-start  m-0 text-red-500">Remove</Typography>
                        </div>,
                        onClick: (): void => {
                            openModal(ApBillModals.DeleteItem, item);
                        }
                    },
                ]} />

        </div>
    </div>;
};
