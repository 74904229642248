import create from 'zustand';
import { isEqual, omit, omitBy } from 'lodash';
import { ApBill, ApBillStatus, GetApBillsParams } from '../network/AccountsReceivable.network';
import { getUrlSearchParamsAsObject, updateUrlSearchParams } from '@/utils/UrlUtils';

export type SelectedBillMap = Record<string, ApBill>;
type SearchParams = GetApBillsParams & { page?: number };
export enum ApBillsModals {
    UploadBill = 'uploadBill',
}
export type ApBillStoreState = {
    searchParams: SearchParams,
    modals: Partial<Record<ApBillsModals, boolean>>,
};

type ApBillStoreActions = {
    updateSearchParams: (params: Partial<SearchParams>) => void,
    haveSearchParamsChanged?: () => boolean,
    resetPagination: () => void,
    reset: () => void,
    setModal: (modal: ApBillsModals, show: boolean) => void,

};

export type ApBillStore = ApBillStoreState & ApBillStoreActions;
const searchParamsFromURL = (): Partial<SearchParams> => {
    const searchParamsEntries = getUrlSearchParamsAsObject<Partial<SearchParams>>();
    return {
        ...searchParamsEntries,
        // Split the customer_id and status query params into arrays, this values are coming from the URL as comma separated strings
        customer_id: typeof searchParamsEntries?.customer_id === 'string' && searchParamsEntries?.customer_id !== '' ? (searchParamsEntries.customer_id as string)?.split(',') : [],
        status: typeof searchParamsEntries?.status === 'string' && searchParamsEntries?.status !== '' ? (searchParamsEntries.status as string)?.split(',') as ApBillStatus[] : [],
    };
};

const AP_BILL_STORE_INITIAL_STATE: ApBillStoreState = {
    searchParams: {
        limit: 20,
        offset: 0,
        page: 0,
        customer_id: [],
        ...searchParamsFromURL(), // Merge the URL search params with the default search params
    },
    modals: {
        uploadBill: false,
    },
};

export const apBillsStoreDefinition = (set, getState): ApBillStore => ({
    ...AP_BILL_STORE_INITIAL_STATE,
    reset: () => set(AP_BILL_STORE_INITIAL_STATE),
    resetPagination: () => set((state) => ({
        searchParams: {
            ...state.searchParams,
            offset: 0,
        },
    })),
    updateSearchParams: (params: Partial<SearchParams>) => set((state) => {
        const newSearchParams = {
            ...state.searchParams,
            ...params,
        };
        updateUrlSearchParams<SearchParams>(newSearchParams);
        return {
            searchParams: newSearchParams,
        };
    }),
    haveSearchParamsChanged: (): boolean => {
        const { searchParams } = getState();
        const ignoredKeys: (keyof SearchParams)[] = ['page', 'offset'];
        // removing from searchParams the ignored keys and undefined values for an accurate comparison
        const currentSearchParams = omitBy(omit(searchParams, ignoredKeys), (value) => value === undefined);
        const initialSearchParams = omit(AP_BILL_STORE_INITIAL_STATE.searchParams, ignoredKeys);
        return !isEqual(currentSearchParams, initialSearchParams);
    },
    setModal: (modal: ApBillsModals, show: boolean) => set((state) => ({
        modals: {
            ...state.modals,
            [modal]: show,
        },
    })),
});

export const useApBillsStore = create<ApBillStore>(apBillsStoreDefinition);
