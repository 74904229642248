import { Modal,
    onMobileSetLarge } from '@notch-ordering/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { AddApBillForm } from '@ap/components/ApBills/AddBillForm/AddApBillForm';

export interface AddBillModalProps {
    isOpen: boolean,
    closeModal: () => void,
    orderUrlsafeKey?: string,
    onBillAdded?: () => void,
}

export const AddApBillModal : React.FC<AddBillModalProps> = ({ isOpen, closeModal, onBillAdded, orderUrlsafeKey }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const description = orderUrlsafeKey ? 'Attach an image of a bill' : 'Search for your supplier and then attach an image of a bill';

    return <>
        <Modal
            isOpen={isOpen}
            hideCloseButton={false}
            close={closeModal}
            modalSize={onMobileSetLarge('MEDIUM')}
            descriptionOnMobile={true}
            title={t('uploadABill')}
            description={description}
            titleSeparatorDesktop>
            <div className="px-4 lg:px-8 overflow-y-auto">
                <AddApBillForm onBillAdded={onBillAdded} onComplete={closeModal} onCancel={closeModal}/>
            </div>
        </Modal>
    </>;
};
