import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// Wrapped Routes

import { APRoutePaths, ARRoutePaths, EPaths } from '@v2/constants/EPaths';
import './stylesheets/main.scss';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ArPageLayout } from '@ar/layouts/ArPageLayout';
import { PayoutsWrapperPage } from '@ar/pages/Payouts/PayoutsWrapper';
import { PayoutsPage } from '@ar/pages/PayoutsPage/PayoutsPage';
import { PayoutDetailsPage } from '@ar/pages/PayoutDetailsPage/PayoutDetailsPage';
import { CustomerDetailsWrapperPage } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsWrapperPage';
import { CustomersPage } from '@ar/pages/CustomersPage/CustomersPage';
import { CustomerDetailsPage } from '@ar/pages/CustomerDetailsPage/CustomerDetailsPage';
import { InvoicesWrapperPage } from '@ar/pages/Invoices/InvoicesWrapper';
import { InvoicesPage } from '@ar/pages/InvoicesPage/InvoicesPage';
import { InvoiceDetailsPage } from '@ar/pages/InvoiceDetailsPage/InvoiceDetailsPage';
import { SettingsPage } from '@ar/pages/SettingsPage/SettingsPage';
import { BrandingPage } from '@ar/pages/BrandingPage/BrandingPage';
import { BankingPage } from '@ar/pages/BankingPage/BankingPage';
import { IntegrationsWrapperPage } from '@ar/pages/IntegrationsPage/IntegrationsWrapperPage';
import { setupAxiosAuth } from '@v2/utils/AxiosUtils';
import { ApPageLayout } from '@ap/layouts/ApPageLayout';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { SignUpPage } from '@ar/pages/SignUpPage/SignUpPage';
import { BillDetailsPage } from '@ap/pages/BillDetailsPage/BillDetailsPage';
import { getCustomerContext,
    getSupplierContext,
    LaunchDarklyContextKind,
    getLoginContext,
    setupLaunchDarkly } from '@v2/utils/LaunchdarklyUtils';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { CustomerNotificationsPage } from '@ar/pages/NotificationsPageV2/CustomerNotificationsPage';
import { CustomerSignUpPage, SIGN_UP_SUPPLIER_ID_KEY } from '@ar/pages/SignUpPage/CustomerSignUpPage';
import { ApBillDetailsPage } from '@ar/pages/ApBillDetailsPage/ApBillDetailsPage';
import { ApBillsPage } from '@ar/pages/ApBillsPage/ApBillsPage';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { BillsPage } from '@/ap/pages/BillsPage/BillsPage';
import { SuppliersPage as ApSuppliersPage } from '@/ap/pages/SuppliersPage/SuppliersPage';
import AppWrapper from '@/containers/app/AppWrapper';
import SignInContainer from '@/containers/signin/SignInContainer';
import { Currency } from '@/ar/network/AccountsReceivable.network';
import { ForgotPasswordPage } from '@/pages/ForgotPasswordPage';
import { ResetPasswordPage } from '@/pages/ResetPasswordPage';
import { SupplierNotificationsPage } from './ar/pages/NotificationPage/SupplierNotificationsPage';
import { CustomerSettingsPage } from '@/ap/pages/SettingsPage/CustomerSettingsPage';
import { EmailInvoicePDFPage } from './pages/SendInvoicePDFEmailPage';
import { DraftInvoicesPage } from './ar/pages/DraftInvoicesPage/DraftInvoicesPage';
import { DraftInvoiceDetailsPage } from './ar/pages/DraftInvoiceDetailsPage/DraftInvoiceDetailsPage';

const PublicRoutes = (): JSX.Element => (
    <BrowserRouter>
        <Routes>
            <Route index element={<Navigate to={EPaths.SIGN_IN}/>}/>
            <Route path="/" element={<AppWrapper/>}>
                <Route path="*" element={<Navigate to={EPaths.SIGN_IN}/>}/>
                <Route path={EPaths.SIGN_IN} element={<SignInContainer/>}/>
                <Route path={EPaths.SIGN_UP} element={<SignUpPage/>}/>
                <Route path={EPaths.FORGOT_PASSWORD} element={<ForgotPasswordPage/>}/>
                <Route path={EPaths.RESET_PASSWORD} element={<ResetPasswordPage/>}/>
                <Route path={EPaths.CUSTOMERS_SIGN_UP} element={<CustomerSignUpPage/>}/>
                <Route path={EPaths.EMAIL_INVOICE_PDF} element={<EmailInvoicePDFPage/>}/>
            </Route>
        </Routes>

    </BrowserRouter>
);

const BillRoutes = <Route path={ARRoutePaths.AR_BILLS} element={<InvoicesWrapperPage/>}>
    <Route index element={<BillsPage/>}/>
    <Route path=":invoiceID" element={<BillDetailsPage/>}/>
</Route>;

async function getStripeClient(currency: Currency | undefined) {
    const isUSD = currency === Currency.usd;

    return await loadStripe(
        isUSD ? process.env.STRIPE_US_API_KEY : process.env.STRIPE_API_KEY
    );
}

const APRoutes = (): JSX.Element => {
    const { supplierLoginData } = useSupplierStore.getState();
    const stripePromise = getStripeClient(supplierLoginData?.currency);

    return <Elements stripe={stripePromise}>
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Navigate to={`/${ARRoutePaths.AR_BILLS}`}/>}/>
                <Route path="" element={<ApPageLayout/>}>
                    <Route index element={<Navigate to={`/${ARRoutePaths.AR_BILLS}`}/>}/>
                    {BillRoutes}
                    <Route path={APRoutePaths.AP_SUPPLIERS} element={<ApSuppliersPage/>}/>
                    <Route path={APRoutePaths.AP_SETTINGS} element={<CustomerSettingsPage/>}/>
                    <Route path={APRoutePaths.AP_BILLS} element={<InvoicesWrapperPage/>}>
                        <Route index element={<ApBillsPage/>}/>
                        <Route path=":billID" element={<ApBillDetailsPage/>}/>
                    </Route>
                    {/* <Route path={APRoutePaths.AP_PAYMENT_METHODS} element={<ApPaymentMethodsPage/>}/> */}
                    {/* <Route path={ARRoutePaths.AR_SETTINGS} element={<SettingsPage/>}/> */}
                    {/* <Route path={ARRoutePaths.AR_BRANDING} element={<BrandingPage/>}/> */}
                    {/* <Route path={ARRoutePaths.AR_BANKING} element={<BankingPage/>}/> */}
                    {/* <Route path={ARRoutePaths.AR_INTEGRATION} element={<IntegrationsWrapperPage/>}/> */}
                </Route>
            </Routes>
        </BrowserRouter>
    </Elements>;
};

const ARRoutes = (): JSX.Element => {
    const { supplierLoginData } = useSupplierStore.getState();
    const stripePromise = getStripeClient(supplierLoginData?.currency);

    const isCustomerAndSupplier = useSupplierStore.getState().supplierLoginData?.isCustomerAndSupplier;
    return <Elements stripe={stripePromise}>
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Navigate to={`/${ARRoutePaths.AR_INVOICES}`}/>}/>
                <Route path="" element={<ArPageLayout/>}>
                    <Route index element={<Navigate to={`/${ARRoutePaths.AR_INVOICES}`}/>}/>
                    <Route path={ARRoutePaths.AR_PAYOUTS} element={<PayoutsWrapperPage/>}>
                        <Route index element={<PayoutsPage/>}/>
                        <Route path=":payoutID" element={<PayoutDetailsPage/>}/>
                    </Route>
                    <Route element={<CustomerDetailsWrapperPage/>}>
                        <Route path={ARRoutePaths.AR_CUSTOMERS} element={<CustomersPage/>}/>
                        <Route path={`${ARRoutePaths.AR_CUSTOMERS}/:customerID`} element={<CustomerDetailsPage/>}/>
                    </Route>
                    <Route path={ARRoutePaths.AR_INVOICES} element={<InvoicesWrapperPage/>}>
                        <Route index element={<InvoicesPage/>}/>
                        <Route path=":invoiceID" element={<InvoiceDetailsPage/>}/>
                    </Route>
                    <Route path={ARRoutePaths.AR_DRAFT_INVOICES} element={<InvoicesWrapperPage/>}>
                        <Route index element={<DraftInvoicesPage/>}/>
                        <Route path=":invoiceID" element={<DraftInvoiceDetailsPage/>}/>
                    </Route>
                    <Route path={ARRoutePaths.AR_BILLS} element={<InvoicesWrapperPage/>}>
                        <Route index element={<BillsPage/>}/>
                        {/* <Route path=":invoiceID" element={<InvoiceDetailsPage/>}/> */}
                    </Route>
                    {isCustomerAndSupplier && BillRoutes}
                    <Route path={ARRoutePaths.AR_CUSTOMER_NOTIFICATIONS} element={<CustomerNotificationsPage/>}/>
                    <Route path={ARRoutePaths.AR_SETTINGS} element={<SettingsPage/>}/>
                    <Route path={ARRoutePaths.AR_SUPPLIER_NOTIFICATIONS} element={<SupplierNotificationsPage/>}/>
                    <Route path={ARRoutePaths.AR_BRANDING} element={<BrandingPage/>}/>
                    <Route path={ARRoutePaths.AR_BANKING} element={<BankingPage/>}/>
                    <Route path={ARRoutePaths.AR_INTEGRATION} element={<IntegrationsWrapperPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </Elements>;
};

const RenderRoutes = (): JSX.Element => {
    const ldClient = useLDClient();
    const authStrategy = UserAuthenticationProvider.getAuthenticateStrategy();
    setupAxiosAuth();
    // this means the auth strategy is set and the user is logged in
    if (authStrategy && UserAuthenticationProvider.isARLogin()) {
        if (useSupplierStore.getState().supplierLoginData?.isCustomer) {
            const supplierID = useSupplierStore.getState().supplierLoginData.supplier_id ?? window.localStorage.getItem(SIGN_UP_SUPPLIER_ID_KEY) ?? useSupplierStore.getState().supplier?.id;
            // setup launchdarkly with both customer and supplier context for multi flag evaluation in favor
            // of switching to individual context setup
            setupLaunchDarkly({
                kind: LaunchDarklyContextKind.multi,
                customer: getCustomerContext(useSupplierStore.getState().supplierLoginData?.customer_id),
                arSupplier: getSupplierContext(supplierID),
                loginEmail: getLoginContext(useSupplierStore.getState().supplierLoginData?.email)
            }, ldClient);
            return <APRoutes/>;
        }
        setupLaunchDarkly(getSupplierContext(useSupplierStore.getState().supplierLoginData?.supplier_id), ldClient);
        return <ARRoutes/>;
    }
    return <PublicRoutes/>;
};

const CreateRoutes = () => (
    <RenderRoutes/>
);

export default CreateRoutes;
