import CameraPlusIcon from '@icons/camera-plus-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { Banner, Button, EBannerType, toast, Typography } from '@notch-ordering/ui-components';
import { useMutation } from '@tanstack/react-query';
import { EOnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import { TrackingEvents } from '@v2/constants/Tracking';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { tCommon, tNamespace } from '@v2/i18n';
import { getOcrInvoices } from '@v2/network/FalsePromiseApi';
import { FirstUploadModal } from '@v2/Pages/Uploads/FirstUploadModal';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { mergeClassNames } from '@v2/utils/Helpers';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { uploadApBill } from '@/ar/network/AccountsReceivable.network';
import { DownloadNotchAppModal } from './DownloadNotchAppModal';
import { FileUploadInput, FileUploadInputState } from './FileUploadInput';
import { SupplierSelectInput } from './SupplierSelectInput';

export interface AddApBillModalProps {
    orderUrlsafeKey?: string,
    onCancel?: () => void,
    onBillAdded?: () => void,
    onComplete?: () => void,
}

export const AddApBillForm : React.FC<AddApBillModalProps> = ({ orderUrlsafeKey, onBillAdded, onComplete, onCancel }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const [selectedSupplierUrlsafeKey, setSelectedSupplierUrlsafeKey] = React.useState<string>('');
    const { supplierLoginData } = useSupplierStore();
    const [downloadNotchModalOpen, setDownloadNotchModalOpen] = React.useState<boolean>(false);
    const [firstUploadModalOpen, setFirstUploadModalOpen] = React.useState<boolean>(false);
    const [isSubmitLoading, setIsSubmitLoading] = React.useState<boolean>(false);
    const [isSubmitAndCreateAnotherLoading, setIsSubmitAndCreateAnotherLoading] = React.useState<boolean>(false);
    const [{ files, valid: filesAreValid }, setFileUploadInputState] = React.useState<FileUploadInputState>({ files: [], valid: true });
    const isInvoiceUpload = !orderUrlsafeKey;

    const isMobileWidth: boolean = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';
    // const uploadOfflineInvoicesMutation = useMutation(uploadOfflineInvoices);
    // const uploadOrderAttachmentMutation = useMutation(addImages);

    const uploadApBillMutation = useMutation(uploadApBill);
    const { buyer } = useBuyerHook();
    const hasFilesSelected = files.length > 0;
    const isUploadDisabled = orderUrlsafeKey
        ? !hasFilesSelected
        : !hasFilesSelected || selectedSupplierUrlsafeKey === '';
    const isUploading = isSubmitLoading || isSubmitAndCreateAnotherLoading;
    const { completeTask, isTaskComplete } = useOnboardingStore();

    const checkUploaded = async (buyerUrlsafeKey: string): Promise<boolean> => {
        const ocrInvoices = await getOcrInvoices({ buyerUrlsafeKey }, { limit: 1 });
        return ocrInvoices?.total === 1;
    };

    const cleanUp = ():void => {
        setFileUploadInputState({ files: [], valid: false });
        setSelectedSupplierUrlsafeKey('');
    };

    const onUploadSuccess = (): void => {
        if (!isTaskComplete(buyer.urlsafe, EOnboardingTasks.ADD_BILL)) {
            checkUploaded(buyer.urlsafe).then((result: boolean) => {
                if (result) {
                    setFirstUploadModalOpen(true);
                }
            }).catch((error) => {
                console.warn(error);
            });
        }

        cleanUp();
        setIsSubmitLoading(false);
        setIsSubmitAndCreateAnotherLoading(false);

        toast.show({
            message: t('billSuccessfullyUploaded'),
            icon: <CheckIcon />,
            showClose: true
        });
        completeTask(buyer.urlsafe, EOnboardingTasks.ADD_BILL);
    };

    const uploadInvoice = async (): Promise<void> => {
        await uploadApBillMutation.mutateAsync({
            customerID: supplierLoginData?.customer_id,
            supplierID: supplierLoginData?.supplier_id,
            files
        });
        onUploadSuccess();
    };

    const handleUpload = async (): Promise<void> => {
        await uploadInvoice();
        sendTrackingEvent(TrackingEvents.billSubmitUploadClicked);

        if (onBillAdded) {
            onBillAdded();
        }
    };

    const handleUploadInvoice = async (): Promise<void> => {
        setIsSubmitLoading(true);
        await handleUpload();

        if (onComplete) {
            onComplete();
        }
        setIsSubmitLoading(false);
    };

    const handleUploadAndCreateAnother = async (): Promise<void> => {
        setIsSubmitAndCreateAnotherLoading(true);
        await handleUpload();
        setIsSubmitAndCreateAnotherLoading(false);
    };

    return <>
        <div className="flex flex-col lg:mt-6 lg:gap-5 gap-3 h-full">
            <div className="flex flex-col">
                {!orderUrlsafeKey && <div className="flex flex-col pb-8 relative">
                    <SupplierSelectInput value={selectedSupplierUrlsafeKey} onChange={setSelectedSupplierUrlsafeKey} />
                    <div className="h-px bg-gray-200 absolute bottom-0 -inset-x-4 md:-inset-x-8" />
                </div>}
                <div className={mergeClassNames('flex flex-col pb-6 pt-8', {
                    'gap-1': !isMobileWidth,
                    'gap-2': isMobileWidth,
                })}>
                    <Typography weight="font-semibold" variant="LG-2" className="mb-0">{t('files')}</Typography>
                    <Typography className="text-gray-600 mb-5" variant="LG-1">
                        {t('uploadYourFiles')}<a href="#" target="_blank"> Learn more</a>
                    </Typography>
                    <FileUploadInput files={files} onChange={setFileUploadInputState} />
                </div>
                {!isMobileWidth && <div className="mb-3">
                    <Banner alertType={EBannerType.SUBDUED}
                        body={<div className="flex flex-row justify-starts">
                            <Typography as="div" className="text-gray-700">
                                {t('uploadInMobileApp')}
                            </Typography>
                            <Button as="span"
                                variant="LINK"
                                size="NO_BACKGROUND"
                                className="px-2 py-0 cursor-pointer"
                                onClick={(): void => {
                                    setDownloadNotchModalOpen(true);
                                }}>
                                <Typography as="span">{tCommon('Buttons.download')}</Typography>
                            </Button>
                        </div>}
                        icon={<CameraPlusIcon className="mt-0.5 mb-2 w-4 h-4 text-gray-700"/>}
                        isDismissable={false}/>
                </div>}
            </div>
        </div>
        <div className="sticky bottom-0 border-t border-gray-200">
            <div className="pt-5 bg-white">
                <div className={mergeClassNames('flex gap-2', {
                    'justify-between': isInvoiceUpload,
                    'justify-end': !isInvoiceUpload,
                })}>
                    {!isMobileWidth && <Button variant="TERTIARY_FILLED"
                        size="MEDIUM"
                        onClick={onCancel}>{tCommon('Buttons.cancel')}</Button>}
                    <div className={mergeClassNames(
                        'flex gap-2 justify-end',
                        { 'flex-col-reverse flex-grow': isMobileWidth }
                    )}>
                        {isInvoiceUpload && <Button variant="TERTIARY_FILLED"
                            size={buttonSize}
                            className="w-full lg:w-auto"
                            disabled={!filesAreValid || isUploadDisabled || isUploading}
                            loading={isSubmitAndCreateAnotherLoading}
                            onClick={handleUploadAndCreateAnother}>{t('Buttons.submitAndCreateAnotherBill')}</Button>}
                        <Button variant="SECONDARY"
                            size={buttonSize}
                            className="w-full lg:w-auto"
                            // disabled={!filesAreValid || isUploadDisabled || isUploading}
                            loading={isSubmitLoading}
                            onClick={handleUploadInvoice}>{tCommon('Buttons.submit')}</Button>
                    </div>
                </div>
            </div>
        </div>
        {downloadNotchModalOpen && <DownloadNotchAppModal isOpen={downloadNotchModalOpen}
            close={(): void => setDownloadNotchModalOpen(false)} />}
        {firstUploadModalOpen && <FirstUploadModal isOpen={firstUploadModalOpen}
            close={(): void => setFirstUploadModalOpen(false)} />}
    </>;
};
