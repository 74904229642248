import { Button, Separator, Typography, Select } from '@notch-ordering/ui-components';
import React from 'react';
import { ARRoutePaths, APRoutePaths } from '@v2/constants/EPaths';
import ReceiptIcon from '@icons/receipt-icon.svg';
import ReceiptActiveIcon from '@icons/receipt-active-icon.svg';
import SettingsIcon from '@icons/setting-icon.svg';
import HandWaveIcon from '@icons/hand-wave-icon.svg';
import SettingsActiveIcon from '@icons/setting-active-icon.svg';
import TruckIcon from '@icons/truck-icon.svg';
import TruckActiveIcon from '@icons/truck-active-icon.svg';
import LogOutIcon from '@icons/log-out-icon.svg';
import NotchLogoImg from '@images/notch-logo.svg';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { SideNavigationLink } from '@ar/components/SideNavigation/SideNavigationLink';
import { LDFlagSet, useFlags } from 'launchdarkly-react-client-sdk';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';
import { queryClient } from '@/containers/app/Root';
import { FeatureFlags as FeatureFlagEnum } from '../constants';
import { useGetCustomerPaymentMethods, useGetCustomerSupplierMappings } from '@/ar/hooks/queries/CustomerQueries.hook';
import { getSupplierID } from '@/ap/utils/SupplierUtils';

const settingsSubmenu: string[] = [APRoutePaths.AP_SETTINGS];

type SideMenuNavigationItem = {
    to: ARRoutePaths | APRoutePaths,
    icon?: React.ReactNode,
    activeIcon?: React.ReactNode,
    label: string,
    featureFlags?: FeatureFlags[],
};

type FeatureFlags = {
    name: FeatureFlagEnum,
    value: boolean,
};

export const TOP_LEVEL_AP_MENU_ITEMS: SideMenuNavigationItem[] = [
    {
        label: 'Bills',
        to: ARRoutePaths.AR_BILLS,
        icon: <ReceiptIcon/>,
        activeIcon: <ReceiptActiveIcon className="text-teal-500"/>
    },
    {
        label: 'Suppliers',
        to: APRoutePaths.AP_SUPPLIERS,
        icon: <TruckIcon/>,
        activeIcon: <TruckActiveIcon className="text-teal-500"/>
    },
    {
        label: 'Settings',
        to: APRoutePaths.AP_SETTINGS,
        icon: <SettingsIcon/>,
        activeIcon: <SettingsActiveIcon className="text-teal-500"/>
    },
    {
        label: 'AP Bills',
        to: APRoutePaths.AP_BILLS,
        icon: <ReceiptIcon/>,
        activeIcon: <ReceiptActiveIcon className="text-teal-500"/>,
        featureFlags: [{ name: FeatureFlagEnum.apBillEditor, value: true }]
    }
];

const SETTINGS_SUBMENU: SideMenuNavigationItem[] = [
];

export const apFilterByFeatureFlags = (
    item: SideMenuNavigationItem,
    flags: LDFlagSet
) => (item.featureFlags ? item.featureFlags.some((flag) => flags[flag.name] === flag.value) : true);

export const ApSideNavigation: React.FC = () => {
    const { supplierLoginData, setSupplierLoginData } = useSupplierStore();
    const location = useLocation();
    const pathname = location.pathname.split('/')[1];
    const flags = useFlags();
    const navigate = useNavigate();
    const { data } = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id
    });

    const showSettingsSubmenu = pathname.includes(APRoutePaths.AP_SETTINGS) || settingsSubmenu.includes(pathname);
    const trackEvent = (label: string) => {
        sendTrackingEvent({
            ...TrackingEvents.navigationMenuClicked,
            label,
        });
    };

    const supplierID = getSupplierID(supplierLoginData, data);

    const { data: paymentMethodsResponse } = useGetCustomerPaymentMethods({
        customerID: supplierLoginData?.customer_id,
        supplierID
    });

    const hasNoPaymentMethods = paymentMethodsResponse?.payment_methods?.length === 0;

    const filteredTopLevelApMenuItems = TOP_LEVEL_AP_MENU_ITEMS.filter((item) => apFilterByFeatureFlags(item, flags));
    const filteredSettingsSubmenu = SETTINGS_SUBMENU.filter((item) => apFilterByFeatureFlags(item, flags));
    const hasCustomer = !!supplierLoginData?.customer_business_name && !!supplierLoginData?.customer_id;

    const customerDropDownReady = flags?.customerProfileDropdown && supplierLoginData?.customers?.length > 1;

    // If the dropdown flag is on show the selector, if not then just show the default customer name
    return <nav className="lg:w-[260px] w-[260px] h-full bg-white border-r border-r-gray-200 flex flex-col">
        <div className="px-5 py-5 ">
            <NotchLogoImg title="notch" className="w-14 h-10 w-16 object-contain mb-3.5 rounded-md"/>
            { customerDropDownReady && hasCustomer && <Typography weight="font-medium truncate">{
                <Select
                    label={'Profiles'}
                    required={true}
                    value={supplierLoginData?.customer_id}
                    variant="SMALL"
                    options={supplierLoginData?.customers.map(
                        ({ id, businessName }) => ({ value: id, label: businessName })
                    )}
                    onChange={(event) => {
                        const newCustomer = supplierLoginData?.customers.find((customer) => customer.id === event.target.value);
                        const newSupplierLoginData = {
                            ...supplierLoginData,
                            customer_id: event.target.value,
                            customer_business_name: newCustomer.businessName,
                            currency: newCustomer.currency,
                            customer_country: newCustomer.country
                        };
                        setSupplierLoginData(newSupplierLoginData);
                        // Refreshes the whole page so the router is re-initialized in case the currency changed
                        window.location.reload();
                    }}/>
            }</Typography>}
            {!customerDropDownReady && <Typography weight="font-medium truncate">{supplierLoginData?.customer_business_name ?? ''}</Typography>}
        </div>
        <Separator/>
        <div className="flex flex-col gap-y-px px-4 mt-4">
            {filteredTopLevelApMenuItems.map((item) => <SideNavigationLink
                to={item.to}
                onClick={() => trackEvent(item.label)}
                activeIcon={item.activeIcon}
                icon={item.icon}
                isSubmenuActive={item.to === ARRoutePaths.AR_SETTINGS ? settingsSubmenu.includes(pathname) : false}
                key={item.label}>
                {item.label}
            </SideNavigationLink>)}

            {showSettingsSubmenu && <>
                {filteredSettingsSubmenu.map(
                    (item) => <SideNavigationLink
                        to={item.to}
                        onClick={() => trackEvent(item.label)}
                        key={item.label}>
                        {item.label}
                    </SideNavigationLink>,
                )}

            </>}
            <Button
                as="div"
                hidden={!hasNoPaymentMethods}
                className="bg-teal-100 text-gray-800 p-4 rounded cursor-pointer flex item-center gap-2"
                onClick={(): void => navigate(`/${APRoutePaths.AP_SETTINGS}`)}>
                <HandWaveIcon className="text-orange-500 fill-teal-500 w-4 h-4 shrink-0"/>
                <span>Add a payment method to pay your bills in just a couple of clicks!</span>
            </Button>
        </div>
        <div className="mt-auto px-4 mb-4">
            <SideNavigationLink
                to={'/'}
                activeIcon={<LogOutIcon className="text-teal-500 fill-teal-500"/>}
                onClick={() => {
                    trackEvent('Account');
                    queryClient.invalidateQueries();
                    UserAuthenticationProvider.logOut();
                }}
                icon={<LogOutIcon/>}
                key={'Logout'}>
                Logout
            </SideNavigationLink>

        </div>
    </nav>;
};
