import { Badge, GridColDef, Typography } from '@notch-ordering/ui-components';
import { GetTransactionItem, TransactionCharge } from '@ar/network/AccountsReceivable.network';

import React, { ReactNode } from 'react';
import PaymentMethodBadge from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { z } from 'zod';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { ApBillItemsActionButtonColumn } from '@/ar/components/ApBillDetails/ApBillItemsActionButtonColumn';
import { cellClassName, headerClassName } from '../PayoutsPage/PayoutsPageConstants';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
    flex: 1,
};

export const BILL_ITEMS_COLUMNS = {
    name: 'description',
    quantity: 'quantity',
    unitPrice: 'price',
    tax: 'tax_amount',
    total: 'total',
    actions: 'actions'
} as const;
export const getBillItemColumns = (): GridColDef<GetTransactionItem>[] => [{
    ...gridColDef,
    field: BILL_ITEMS_COLUMNS.name,
    headerName: 'Name',
},
{
    ...gridColDef,
    field: BILL_ITEMS_COLUMNS.quantity,
    headerName: 'Quantity',
},
{
    ...gridColDef,
    field: BILL_ITEMS_COLUMNS.unitPrice,
    headerName: 'Unit Price',
    renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
},
{
    ...gridColDef,
    field: BILL_ITEMS_COLUMNS.tax,
    headerName: 'Tax',
    renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
},
{
    ...gridColDef,
    field: BILL_ITEMS_COLUMNS.total,
    headerName: 'Total',
    renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
},
{
    ...gridColDef,
    headerName: '',
    flex: 1,
    field: BILL_ITEMS_COLUMNS.actions,
    cellClassName: 'overflow-visible', // This must be set for the popover to be visible
    headerClassName: 'hidden',
    renderCell: ({ row }): ReactNode => <ApBillItemsActionButtonColumn item={row}/>,
},
];

export const BILL_CREDITS_COLUMNS = {
    amount: 'amount',
    reason: 'description',
    updatedAt: 'updated_at',
} as const;

export const getBillCreditColumns = (): GridColDef<GetTransactionItem>[] => [
    {
        ...gridColDef,
        field: BILL_CREDITS_COLUMNS.amount,
        headerName: 'Amount',
        renderCell: ({ value }) => <div className="flex gap-4 items-center"><span>{formatAsCurrency(centsToDollars(value))}</span><Badge variant="GRAY">Used</Badge> </div>,
    },
    {
        ...gridColDef,
        field: BILL_CREDITS_COLUMNS.updatedAt,
        headerName: 'Issue date',
        renderCell: ({ value }) => (value ? formatIsoStringToUtcDate(new Date(value).toISOString(), 'MMM d, yyyy') : '--'),
    },
];
export const BILL_PAYMENTS_COLUMNS = {
    amount: 'amount',
    paymentMethod: 'paymentMethod',
    date: 'created_at',
    status: 'status'
} as const;

export const getBillPaymentColumns = (): GridColDef<TransactionCharge>[] => [
    {
        ...gridColDef,
        field: BILL_PAYMENTS_COLUMNS.amount,
        headerName: 'Amount',
        renderCell: ({ value }) => formatAsCurrency(centsToDollars(value)),
    },
    {
        ...gridColDef,
        field: BILL_PAYMENTS_COLUMNS.paymentMethod,
        headerName: 'Method',
        renderCell: ({ row }) => (row?.payment_method ? <PaymentMethodBadge brand={row.payment_method.brand} last4={row.payment_method.last4}/> : '--'),
    },
    {
        ...gridColDef,
        field: BILL_PAYMENTS_COLUMNS.date,
        headerName: 'Date',
        renderCell: ({ value }) => (value ? formatIsoStringToUtcDate(new Date(value).toISOString(), 'MMM d, yyyy') : '--'),
    },
    {
        ...gridColDef,
        field: BILL_PAYMENTS_COLUMNS.status,
        headerName: 'Status',
        renderCell: ({ value }) => (value === 'canceled' ? <Badge variant="RED" className="truncate font-medium">failed</Badge> : <Badge variant="GREEN" className="truncate font-medium">{value}</Badge>),
    },

];

export type CustomerBlockProps = {
    title: React.ReactNode,
    value: React.ReactNode,
};

export const PanelBlock : React.FC<CustomerBlockProps> = ({ title, value }) => <div className="flex flex-col gap-2">
    <Typography as="div" className="text-gray-600 font-medium">
        {title}
    </Typography>
    <Typography as="div">
        {value}
    </Typography>
</div>;

export const gridSX = {
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
        py: '20px',
        alignItems: 'flex-start',
    },
    '& .MuiDataGrid-virtualScroller': {
        minHeight: 'auto',
        overflow: 'visible!important'
    },
};

export const EmptyState : React.FC<{ text:string }> = ({ text }) => <Typography as="div" className="text-gray-600">
    {text}
</Typography>;

export const apBillValidationSchema = z.object({
    external_id: z.string().nonempty('External ID is required'),
    bill_number: z.string().nonempty('Bill number is required'),
    po_number: z.string().nonempty('PO number is required'),
    bill_date: z.string().nonempty('Bill date is required'),
    due_date: z.string().nonempty('Due date is required'),
    bill_total: z.number({
        required_error: 'Bill total is required',
        invalid_type_error: 'Bill total is required'
    }).default(0),
    terms: z.number().int('Terms must be an integer').nonnegative('Terms cannot be negative').default(0),
    customer_id: z.string({
        required_error: 'The selection of a customer is required',
        invalid_type_error: 'The selection of a customer is required'
    }).nonempty('The selection of a customer is required'),
    supplier_id: z.string().uuid('Supplier ID must be a valid UUID').nonempty('Supplier ID is required'),
    offline_credits_payment: z.number({
        required_error: 'Offline credits payment is required',
        invalid_type_error: 'Offline credits payment is required'
    }).default(0),
});
