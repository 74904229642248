import React, { useMemo } from 'react';
import { Checkbox, PopoverItem } from '@notch-ordering/ui-components';

import { ApBillStatus } from '@ar/network/AccountsReceivable.network';
import { FilterLabelProps } from '@ar/components/shared/FilterLabel/FilterLabel';
import { FilterButton } from '@/components/shared/FilterButton';
import { useApBillsStore } from '@/ar/stores/ApBillsStore';
import { ApBillStatusBadge, getPaymentStatusText } from '../../Invoices/ApBillStatusBadge';

export const apBillPaymentStatus: ApBillStatus[] = [
    ApBillStatus.Draft,
    ApBillStatus.Published,
    ApBillStatus.Processing,
    ApBillStatus.Archived,
];
export const DraftFilterMultipleLabel = ({ isChecked, label, showStatusBadge }: FilterLabelProps & { showStatusBadge?: boolean }): JSX.Element => (
    <Checkbox
        className=" [&>*:first-child]:shrink-0 items-start"
        checked={isChecked}
        label={showStatusBadge ? <ApBillStatusBadge badgeProps={{ stopPropagation: false, }} status={label as ApBillStatus}/> : label}
        value={`${label}`}
        onChange={() => {}}/>
);

export const ApBillStatusFilterButton: React.FC = () => {
    const { searchParams, updateSearchParams } = useApBillsStore();
    const isChecked = searchParams.status?.length > 0;

    const statusItems = useMemo<PopoverItem[]>(() => apBillPaymentStatus.map((status) => {
        const isStatusChecked = searchParams.status?.includes(status);
        return ({
            label: <DraftFilterMultipleLabel showStatusBadge label={status} isChecked={isStatusChecked} />,
            onClick: (): void => {
                const updatedStatus = isStatusChecked ? searchParams?.status.filter((s) => s !== status) : [...(searchParams?.status ?? []), status];
                updateSearchParams({ status: updatedStatus, page: 0 });
            },
        });
    }), [searchParams?.status]);

    return (
        <FilterButton
            isChecked={isChecked}
            onClearFilter={(): void => {
                updateSearchParams({ status: undefined, page: 0 });
            }}
            items={statusItems}
            label={isChecked ? `Status is ${searchParams?.status.map(getPaymentStatusText).join(', ')}` : 'Status'}
            closePanelOnClick={false}/>
    );
};
