import { PageTitle } from '@ar/components/PageTitle';
import { TrackingEvents } from '@ar/constants/TrackingConstants';
import { ApBillSortBy } from '@ar/network/AccountsReceivable.network';
import { ApBillEmailButton, AP_BILL_COLUMNS, getColumns } from '@ar/pages/ApBillsPage/ApBillsPageConstants';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { GridSortModel } from '@mui/x-data-grid';
import { Button, Loading, NotchDataGrid, Typography } from '@notch-ordering/ui-components';
import { APRoutePaths } from '@v2/constants/EPaths';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { ApBillFilters } from '@/ar/components/ApBills/ApBillFilters/ApBillFilters';
import '../../../styles/dataPage.scss';

import { AddApBillModal } from '@/ap/components/ApBills/AddApBillModal/AddApBillModal';
import { useGetApBills } from '@/ar/hooks/queries/ApBillsQueries.hook';
import { ApBillsModals, useApBillsStore } from '@/ar/stores/ApBillsStore';

/**
 * The Ap Bills page
 *
 * @returns JSX Element
 */
export const ApBillsPage = (): JSX.Element => {
    useDocumentTitle('Bills - Notch');

    const navigate = useNavigate();
    const { searchParams, updateSearchParams, haveSearchParamsChanged, setModal, modals } = useApBillsStore();
    const { supplierLoginData } = useSupplierStore();
    const { data: apBillsData, status, isLoading: isApBillsLoading } = useGetApBills({
        searchParams: {
            ...searchParams,
            offset: searchParams.page * searchParams.limit,
            customer_id: [supplierLoginData?.customer_id],
            limit: searchParams.limit,
        }
    });
    const isLoading = isApBillsLoading;
    const [hoveredRowID, setHoveredRowID] = useState<string>('');
    const apBills = apBillsData?.bills || [];
    const draftInvoiceRows = apBills.map((bill) => ({
        ...bill,
        isHovered: hoveredRowID === bill.id
    }));
    const hasResults = apBills.length > 0;
    const successStatus = status === 'success';
    const showResults = successStatus && hasResults;
    const emptyFilteredResults = successStatus && !hasResults && haveSearchParamsChanged();
    const noResults = successStatus && apBillsData?.total === 0 && !haveSearchParamsChanged();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined,
        };

        if (!field || !sort) {
            updateSearchParams({ sort_by: undefined });
            return;
        }

        const direction = sort === 'asc' ? '' : '-';
        const fieldToSortBy: ApBillSortBy = `${direction}${field}` as ApBillSortBy;
        updateSearchParams({
            sort_by: fieldToSortBy,
        });
    }, []);

    const getColumnsVisibilityModel = (): Record<string, boolean> => {
        // if keys are not present in the object by default, all the columns are visible.
        if (supplierLoginData.gpo) {
            return {};
        }
        const visibilityModel = {
            [AP_BILL_COLUMNS.supplier]: false,
        };

        return visibilityModel;
    };

    return (
        <>
            <div className="flex items-start gap-2">
                <div className="flex items-start flex-col gap-1">
                    <PageTitle>Bills</PageTitle>
                    <ApBillEmailButton />
                </div>
                <Button variant="SECONDARY"
                    size="SMALL"
                    className="ml-auto"
                    onClick={(): void => {
                        setModal(ApBillsModals.UploadBill, true);
                    }
                    }>Upload bills</Button>
            </div>

            <div className="mt-2">
                <ApBillFilters />
                {isLoading && <Loading isDark className="mt-10" />}
                {showResults && <>
                    <NotchDataGrid
                        autoHeight
                        loading={isLoading}
                        columnVisibilityModel={getColumnsVisibilityModel()}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableSelectionOnClick
                        disableColumnFilter
                        keepNonExistentRowsSelected
                        disableColumnSelector
                        disableColumnMenu
                        columns={getColumns()}
                        page={searchParams.page}
                        rowCount={apBillsData?.total ?? 0}
                        rowsPerPageOptions={[searchParams.limit]}
                        pageSize={searchParams.limit}
                        paginationMode="server"
                        rowHeight={61}
                        initialState={{
                            sorting: {
                                sortModel: [{
                                    field: searchParams.sort_by?.replace(/^-/, ''),
                                    sort: searchParams.sort_by?.startsWith('-') ? 'desc' : 'asc',
                                }],
                            }
                        }}
                        onPageChange={(newPage): void => {
                            updateSearchParams({ page: newPage });
                            const pageChangeEvent = newPage > searchParams.page ? TrackingEvents.goToNextPageClicked : TrackingEvents.goToPreviousPageClicked;
                            sendTrackingEvent(pageChangeEvent);
                        } }
                        rows={draftInvoiceRows}
                        onRowClick={({ row }) => {
                            if (!row.id) return;
                            navigate(`/${APRoutePaths.AP_BILLS}/${row.id}`);
                        } }
                        componentsProps={{
                            row: {
                                onMouseEnter: (event: React.MouseEvent<HTMLElement>) => {
                                    const rowID = event.currentTarget.getAttribute('data-id');
                                    if (rowID) {
                                        setHoveredRowID(rowID);
                                    }
                                },
                                onMouseLeave: () => setHoveredRowID(null)
                            }
                        }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }} />
                </>}
                {emptyFilteredResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold">No results found</Typography>
                    <Typography className="text-gray-600">Try searching for something else or adjusting your filters</Typography>
                </div>}
                {noResults && <div className="flex flex-col gap-2 items-center justify-center mt-32">
                    <Typography variant="LG-2" weight="font-semibold m-0">No results found</Typography>
                    <Typography className="text-gray-600 m-0">No bills found for this supplier</Typography>
                    <ApBillEmailButton/>
                </div>}
            </div>
            <AddApBillModal
                onBillAdded={(): void => {

                }}
                closeModal={(): void => {
                    setModal(ApBillsModals.UploadBill, false);
                }}
                isOpen={modals.uploadBill}/>

        </>

    );
};
