import React, { useMemo } from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';
import { EIntegrationType, SupplierData, updateSupplier, UpdateSupplierData } from '@ar/network/AccountsReceivable.network';
import { useGetSupplierConfiguration } from '@ar/hooks/queries/IntegrationsQuery.hook';
import { EConfigurationType, getConnectionStatus, SupplierConfiguration } from '@ar/network/Bushwhack.network';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { v4 } from 'uuid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BUSHWHACK_API, BUSHWHACK_API_KEY } from '@/utils/constants';
import { getConfigTypeFromSupplierIntegrationName,
    isIntegrationReady,
    isNotchedHandledIntegration,
    getPaymentAccountKey,
    integrationNameRutter,
    getLastSyncAt,
    getIntegrationTypeForRoutePath } from './IntegrationUtils';

interface IIntegration {
    name: SupplierData['integration_name'],
    last_synced_at: SupplierData['last_synced_at'],
    id?: string,
    isEnabled?: boolean,
    created_at: string,
    type: SupplierConfiguration['type'],
    isConnected?: boolean,
    integrationType: EIntegrationType,
    integrationSyncStatus?: boolean,
    paymentAccountID?: string,
}

export enum EQBOIntegrationType {
    WORKATO = 'workato',
    REQUIRESLOGIN = 'requiresLogin',
    RUTTER = 'rutter',
    NATIVE = 'native',
}

export type IntegrationModals = EIntegrationType.Fidelio | EIntegrationType.NetSuite | EIntegrationType.Spire | EIntegrationType.Sage300 | EIntegrationType.QBO | EIntegrationType.Xero | EIntegrationType.Dynamics365;
export interface IIntegrationsProviderProps {
    integration: IIntegration | null,
    isLoading: boolean,
    integrationsTab: IntegrationsTabs,
    setIntegrationsTab: React.Dispatch<React.SetStateAction<IntegrationsTabs>>,
    modals: Record<IntegrationModals, boolean>,
    setModal: (modal: IntegrationModals, show: boolean) => void,
    supplierIntegrationConfiguration: SupplierConfiguration[],
    updateSupplierIntegration: (params: UpdateSupplierData, configType?: EConfigurationType) => void,
    removeSupplierIntegration?: () => void,
    isIntegrationSyncComplete?: boolean,
}
/**
 *
 * IntegrationsContext - Context for the integrations page
 */
export const IntegrationsContext = React.createContext<IIntegrationsProviderProps>({
    integration: null,
    isLoading: false,
    integrationsTab: IntegrationsTabs.Active,
    setIntegrationsTab: () => null,
    modals: {
        fidelio: false,
        netsuite: false,
        spire: false,
        sage_300: false,
        qbo: false,
        xero: false,
        dynamics365: false,
    },
    setModal: () => null,
    supplierIntegrationConfiguration: [],
    updateSupplierIntegration: () => null,
    removeSupplierIntegration: () => null,
    isIntegrationSyncComplete: false,
});

export type IntegrationWebhookUrls = Partial<Record<EConfigurationType, string>>;

const arRutterWebhookUrl: string = `${BUSHWHACK_API}/rutter/ar/ar-webhook?key=${BUSHWHACK_API_KEY}`;
const arQboWebhookUrl: string = `${BUSHWHACK_API}/qbo/ar/ar-webhook?key=${BUSHWHACK_API_KEY}`;
export const integrationWebhookUrls: IntegrationWebhookUrls = {
    [EConfigurationType.Fidelio]: `${BUSHWHACK_API}/fidelio/ar-webhook?key=${BUSHWHACK_API_KEY}`,
    [EConfigurationType.Spire]: `${BUSHWHACK_API}/spire/ar-webhook?key=${BUSHWHACK_API_KEY}`,
    [EConfigurationType.Sage300]: `${BUSHWHACK_API}/sage-300/ar-webhook?key=${BUSHWHACK_API_KEY}`,
    [EConfigurationType.ArRutter]: arRutterWebhookUrl,
    [EConfigurationType.QBO]: arQboWebhookUrl,
};

export const getIntegrationWebhookUrl = (configType: EConfigurationType): string | null => (configType in integrationWebhookUrls ? integrationWebhookUrls[configType] : null);

/**
 * Integrations provider
 *
 * @param children
 * @function Object() { [native code] }
 */
export const IntegrationsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const updateSupplierMutation = useMutation(updateSupplier);
    const flags = useFlags();
    const [integrationsTab, setIntegrationsTab] = React.useState<IntegrationsTabs>(IntegrationsTabs.Active);
    const [modals, setModals] = React.useState<IIntegrationsProviderProps['modals']>({
        fidelio: false,
        netsuite: false,
        spire: false,
        sage_300: false,
        qbo: false,
        xero: false,
        dynamics365: false
    });

    const { supplier, supplierLoginData } = useSupplierStore();

    const { refetch: refetchSupplier } = useGetSupplier({
        supplierID: supplierLoginData?.supplier_id,
    });

    const { data: supplierConfiguration, isLoading, refetch: refetchSupplierConfiguration } = useGetSupplierConfiguration(supplierLoginData.supplier_id);
    const connectedIntegration = supplierConfiguration?.results?.find((i) => i.ownerID === supplierLoginData.supplier_id);

    const integrationType = (connectedIntegrationType: EConfigurationType):string => {
        switch (connectedIntegrationType) {
            case EConfigurationType.ArRutter:
                return integrationNameRutter(connectedIntegration?.data.platform);
            default:
                return connectedIntegration?.type;
        }
    };

    const paymentAccountKey = getPaymentAccountKey(connectedIntegration?.type);

    const accountingPlatform = getIntegrationTypeForRoutePath(connectedIntegration?.type);
    const configID = connectedIntegration?.id;
    const isPlatformEnabled = !!accountingPlatform && !!configID;
    const { data: connectionStatus } = useQuery(['FETCH_ACCOUNTING_INTEGRATION_INITIAL_SYNC', configID], () => getConnectionStatus(
        configID,
        accountingPlatform
    ), { enabled: isPlatformEnabled,
        cacheTime: 0,
        retry: 1 });

    const isReady = connectionStatus?.isReady;
    const syncStatus = isIntegrationReady(connectedIntegration?.type, isReady);
    const lastSyncAt = getLastSyncAt(connectedIntegration, connectionStatus);

    const supplierIntegrationName = integrationType(connectedIntegration?.type) ?? supplier?.integration_name ?? '';
    const isQBOWorkato = supplierIntegrationName === EIntegrationType.QBO && flags.qboIntegrationType === EQBOIntegrationType.WORKATO;
    const integrationNameForConfigurationType = isQBOWorkato ? EIntegrationType.QBOWORKATO : supplierIntegrationName;
    const isNotchedHandled = isNotchedHandledIntegration(integrationNameForConfigurationType as EIntegrationType);

    let integration:IIntegration = null;

    if (!isLoading) {
        if (connectedIntegration) {
            const { createdAt, id, isEnabled, data, type } = connectedIntegration;
            const integrationName = integrationNameRutter(data?.platform) ?? type ?? '';
            integration = {
                id,
                name: integrationName.toLowerCase().replace(/-/g, '_'),
                last_synced_at: lastSyncAt,
                created_at: createdAt,
                isEnabled,
                type,
                isConnected: true,
                integrationType: supplierIntegrationName as EIntegrationType,
                integrationSyncStatus: syncStatus,
                paymentAccountID: data[paymentAccountKey]
            };
        } else if (supplierIntegrationName && !isNotchedHandled) {
            integration = {
                id: v4(),
                created_at: '',
                // by default if no record in bushwhack, we assume it is enabled
                isEnabled: true,
                type: getConfigTypeFromSupplierIntegrationName(integrationNameForConfigurationType as EIntegrationType),
                name: supplierIntegrationName,
                last_synced_at: '',
                isConnected: !isNotchedHandledIntegration(supplierIntegrationName as EIntegrationType),
                integrationType: supplierIntegrationName as EIntegrationType
            };
        }
    }

    React.useEffect(() => {
        if (integrationsTab === IntegrationsTabs.Active && !integration) {
            // retrieve supplier configuration data
            refetchSupplierConfiguration();
            // retrieve supplier data
            refetchSupplier();
        }
    }, [integrationsTab]);

    const updateSupplierIntegration = (params:UpdateSupplierData, configType?: EConfigurationType):void => {
        const cashApplicationWebhookUrl = params?.integration_name ? getIntegrationWebhookUrl(configType) : null;
        updateSupplierMutation.mutate({
            supplierID: supplierLoginData?.supplier_id,
            body: {
                cash_application_webhook_url: cashApplicationWebhookUrl,
                ...params,
            },
        }, {
            onSuccess: () => {
                refetchSupplier();
            },
        });
    };

    const removeSupplierIntegration = ():void => {
        updateSupplierIntegration({
            integration_name: null,
        });
    };

    const value:IIntegrationsProviderProps = useMemo(() => ({
        integration,
        isLoading,
        integrationsTab,
        setIntegrationsTab,
        supplierIntegrationConfiguration: supplierConfiguration?.results ?? [],
        modals,
        updateSupplierIntegration,
        removeSupplierIntegration,
        setModal: (modal: IntegrationModals, show: boolean): void => {
            setModals({
                ...modals,
                [modal]: show,
            });
        },
        isIntegrationSyncComplete: syncStatus,
    }), [integration, isLoading, integrationsTab, setIntegrationsTab, modals, setModals, supplierConfiguration, syncStatus, updateSupplierIntegration, removeSupplierIntegration]);

    return <IntegrationsContext.Provider value={value}>{children}</IntegrationsContext.Provider>;
};

export const useIntegrationsContext = (): IIntegrationsProviderProps => React.useContext(IntegrationsContext);
