import React from 'react';
import { Tabs } from '@notch-ordering/ui-components';
import { PageTitle } from '@ar/components/PageTitle';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';
import { useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { IntegrationsActive } from '@ar/components/Integrations/IntegrationsActive/IntegrationsActive';
import { IntegrationsExplore } from '@ar/components/Integrations/IntegrationsExplore/IntegrationsExplore';
import { FidelioConnectModal } from '@ar/components/Integrations/IntegrationModals/FidelioConnectModal';
import { AccountingArModal } from '@ar/components/Integrations/IntegrationModals/AccountingArModal';
import { SpireConnectModal } from '@ar/components/Integrations/IntegrationModals/SpireConnectModal/SpireConnectModal';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { Sage300ConnectModal } from '@/ar/components/Integrations/IntegrationModals/Sage300ConnectModal';
import { EIntegrationType } from '@/ar/network/AccountsReceivable.network';

/**
 * The AR Integrations page
 *
 * @returns JSX Element
 */
export const IntegrationsPage = (): JSX.Element => {
    useDocumentTitle('Integrations - Notch');

    const {
        integrationsTab,
        setIntegrationsTab,
        modals,
    } = useIntegrationsContext();

    const handleTabChange = (value: string): void => {
        setIntegrationsTab(IntegrationsTabs[value]);
    };

    return (
        <>
            <div className="-mx-10 -my-10 w-[calc(100%+80px)]">
                <PageTitle className="mt-16 mb-8 mx-10">Integrations</PageTitle>
                <div className="px-10 mb-[-2px]">
                    <Tabs
                        onChange={handleTabChange}
                        tabs={Object.values(IntegrationsTabs)}
                        value={integrationsTab} />
                </div>
                <div className="w-full h-px bg-gray-200 -mt-[1px]" />
                {integrationsTab === IntegrationsTabs.Active && <IntegrationsActive />}
                {integrationsTab === IntegrationsTabs.Explore && <IntegrationsExplore />}
            </div>
            {modals.fidelio && <FidelioConnectModal/>}
            {modals.netsuite && <AccountingArModal integrationTypeForModal={EIntegrationType.NetSuite} integrationName="NetSuite"/>}
            {modals.xero && <AccountingArModal integrationTypeForModal={EIntegrationType.Xero} integrationName="Xero"/>}
            {modals.dynamics365 && <AccountingArModal integrationTypeForModal={EIntegrationType.Dynamics365} integrationName="Dynamics 365"/>}
            {modals.spire && <SpireConnectModal/>}
            {modals.sage_300 && <Sage300ConnectModal />}
            {modals.qbo && <AccountingArModal integrationTypeForModal={EIntegrationType.QBO} integrationName="QuickBooks Online"/>}
        </>
    );
};
