import { Badge, GridColDef, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { ApBill, ApBillStatus } from '@ar/network/AccountsReceivable.network';
import WarningIcon from '@icons/warning-icon.svg';
import { ApBillStatusBadge } from '@ar/components/Invoices/ApBillStatusBadge';
import { formatIsoStringToUtcDate } from '@/utils/DateUtils';
import { centsToDollars, formatAsCurrency } from '@/utils/Formatters';

import { CopyButton, CopyButtonFeedback } from '@/ar/components/shared/CopyButton';
import { useSupplierStore } from '@/ar/stores/SupplierStore';

export const headerClassName = 'text-gray-600 font-body text-med';
export const cellClassName = 'text-gray-700 font-body text-med';

interface BillRow extends ApBill {
    isHovered: boolean,
}

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
};

export const AP_BILL_COLUMNS: Record<string, keyof ApBill | string> = {
    billTotal: 'bill_total',
    outstandingBalance: 'outstanding_balance',
    billDate: 'bill_date',
    dueDate: 'due_date',
    billNumber: 'bill_number',
    customer: 'customer',
    status: 'status'

};

export const MissingBillColumField:React.FC<{ text?: string }> = ({ text = 'Missing' }) => <Badge size="SMALL" variant="ORANGE" className="py-0.5">
    <div className="flex items-center gap-3">
        <WarningIcon className="w- h-4 shrink-0" />
        <Typography variant="BASE" weight="font-medium" className=" m-0">{text}</Typography>
    </div>
</Badge>;

export const DEFAULT_PROCESSING_TEXT = '-';

export const getColumns = (): GridColDef<BillRow>[] => [
    {
        ...gridColDef,
        field: AP_BILL_COLUMNS.billNumber,
        headerName: 'Bill #',
        flex: 1,
        sortable: true,
        minWidth: 140,
        cellClassName: 'overflow-visible', // This must be set for the tooltip to be visible
        renderCell: ({ row }) => {
            if (row.status === ApBillStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.bill_number ? <Typography className="truncate">{row.bill_number} </Typography> : <MissingBillColumField />);
        }
    },
    {
        ...gridColDef,
        field: AP_BILL_COLUMNS.status,
        headerName: 'Status',
        minWidth: 120,
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => <ApBillStatusBadge status={row.status as ApBillStatus}/>
    },
    {
        ...gridColDef,
        field: AP_BILL_COLUMNS.billTotal,
        headerName: 'Total Amount',
        minWidth: 300,
        cellClassName: 'overflow-visible', // This must be set for the popover to be visible
        flex: 1,
        sortable: true,
        renderCell: ({ value, row }) => {
            if (row.status === ApBillStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (value ? <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography> : <MissingBillColumField />);
        }
    },
    {
        ...gridColDef,
        field: AP_BILL_COLUMNS.outstandingBalance,
        headerName: 'Remaining Amount',
        minWidth: 160,
        flex: 1,
        sortable: false,
        renderCell: ({ value, row }) => {
            if (row.status === ApBillStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (value ? <Typography className="truncate">{formatAsCurrency(centsToDollars(value))}</Typography> : <MissingBillColumField />);
        }
    },

    {
        ...gridColDef,
        field: AP_BILL_COLUMNS.billDate,
        headerName: 'Issue date',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => {
            if (row.status === ApBillStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.bill_date ? <div>{formatIsoStringToUtcDate(new Date(row.bill_date).toISOString(), 'MMM d, yyyy')}</div> : <MissingBillColumField />);
        },
    },
    {
        ...gridColDef,
        field: AP_BILL_COLUMNS.customer,
        headerName: 'Customer',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => {
            if (row.status === ApBillStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.customer?.businessName ? <div>{row.customer?.businessName}</div> : <MissingBillColumField text="No match" />);
        },
    },
    {
        ...gridColDef,
        field: AP_BILL_COLUMNS.dueDate,
        headerName: 'Due date',
        minWidth: 120,
        flex: 1,
        sortable: true,

        renderCell: ({ row }) => {
            if (row.status === ApBillStatus.Processing) {
                return DEFAULT_PROCESSING_TEXT;
            }
            return (row.due_date ? <div>{formatIsoStringToUtcDate(new Date(row.due_date).toISOString(), 'MMM d, yyyy')}</div> : <MissingBillColumField />);
        },
    }
];

export const ApBillEmailButton: React.FC = () => {
    const { supplierLoginData } = useSupplierStore.getState();
    if (!supplierLoginData?.customer_id) return null;
    const emailToCopy = `${supplierLoginData?.customer_id}${process.env.AP_BILL_EMAIL_ADDRESS}`;

    return (
        <Typography className="text-gray-600 m-0" as="div">
            <div className="flex gap-2 items-center">
                <div className="flex items-center gap-1">
                    <span className="text-gray-600">Email your bills to </span>
                    <a href={`mailto:${emailToCopy}`}
                        target="_blank"
                        rel="noreferrer"

                        className="text-teal-300">{emailToCopy}</a>
                </div>
                <CopyButton
                    buttonClassName="p-0 bg-gray-25 px-1 py-0.5 rounded-md flex items-center gap-1"
                    buttonText="Copy"
                    iconClassName="m-0"
                    textToCopy={emailToCopy}
                    feedbackType={CopyButtonFeedback.TOOLTIP}
                    successMessage="Email copied to clipboard"
                    errorMessage="Error - Email not copied"/>
            </div>
        </Typography>
    );
};
