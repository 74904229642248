import React from 'react';
import { Button, Separator, toast, Typography } from '@notch-ordering/ui-components';
import FidelioLogo from '@ar/assets/images/fidelio-logo.png';
import NetSuiteLogo from '@ar/assets/images/netsuite-logo.svg';
import QuickbooksLogo from '@ar/assets/images/quickbooks-logo.svg';
import XeroLogo from '@ar/assets/images/xero-logo.svg';
import Dynamics365Logo from '@ar/assets/images/dynamics365-logo.svg';
import Sage300Logo from '@ar/assets/images/sage-300-logo.png';
import SpireLogo from '@ar/assets/images/spire-logo.svg';
import { EQBOIntegrationType, IntegrationModals, useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { connectToQBO, EConfigurationType, ERutterPlatform, exchangeRutterToken, GetOAuthConnectURLResponse } from '@ar/network/Bushwhack.network';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { useRutterLink } from 'react-rutter-link';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RUTTER_DEBUG, RUTTER_PUBLIC_KEY } from '@/utils/constants';
import { EIntegrationType, getSupplierToken } from '@/ar/network/AccountsReceivable.network';
import { UserAuthenticationProvider } from '@/auth/UserAuthenticationProvider';

type AvailableIntegration = {
    logo: JSX.Element,
    integrationType: EIntegrationType,
    name: string,
    desc: string,
    openModal?: boolean,
    configType?: EConfigurationType,
    enabled?: boolean,
};

type AvailableIntegrations = AvailableIntegration[];

export enum EPlatformOpen {
    NETSUITE = 'NETSUITE',
    QUICKBOOKS = 'QUICKBOOKS',
    XERO = 'XERO',
    DYNAMICS365 = 'DYNAMICS365'
}

export const platformToSupplierIntegrationName = (platform: ERutterPlatform): EIntegrationType => {
    switch (platform) {
        case ERutterPlatform.QUICKBOOKS:
            return EIntegrationType.QBO;
        case ERutterPlatform.NETSUITE:
            return EIntegrationType.NetSuite;
        case ERutterPlatform.XERO:
            return EIntegrationType.Xero;
        case ERutterPlatform.DYNAMICS365:
            return EIntegrationType.Dynamics365;
        default:
            return null;
    }
};

/**
 * The AR Integrations Explore Tab
 *
 * @returns JSX Element
 */
export const IntegrationsExplore = (): JSX.Element => {
    const { supplierLoginData } = useSupplierStore();
    const flags = useFlags();
    const { setModal, integration: activeIntegration, setIntegrationsTab, updateSupplierIntegration } = useIntegrationsContext();

    const hasActiveIntegrationNotWorkato = !!activeIntegration && activeIntegration.type !== EConfigurationType.Workato;

    const onRutterSuccess = async (publicToken: string): Promise<void> => {
        const response = await exchangeRutterToken(
            {
                ownerID: supplierLoginData.supplier_id,
                rutterToken: publicToken,
                arApiToken: await getSupplierToken(supplierLoginData.supplier_id),
            },
        ).catch((error) => {
            console.error(error);
            toast.show({
                message: 'Unable to exchange publicToken for accessToken',
            });
        });

        if (response) {
            setIntegrationsTab(IntegrationsTabs.Active);
            // update supplier integration
            updateSupplierIntegration({
                integration_name: platformToSupplierIntegrationName(response.data?.platform as ERutterPlatform),
            }, EConfigurationType.ArRutter);
        }
    };

    const rutterConfig = {
        publicKey: RUTTER_PUBLIC_KEY,
        onSuccess: onRutterSuccess,
        debug: RUTTER_DEBUG,
    };

    const { open } = useRutterLink(rutterConfig);

    const onClickAccountingConnect = (integration: AvailableIntegration, isConnected: boolean): void => {
        if (integration?.openModal || isConnected) {
            setModal(integration.integrationType as IntegrationModals, true);
            return;
        }

        if (integration.configType === EConfigurationType.ArRutter) {
            let platform;
            switch (integration.integrationType) {
                case EIntegrationType.QBO:
                    platform = EPlatformOpen.QUICKBOOKS;
                    break;
                case EIntegrationType.NetSuite:
                    platform = EPlatformOpen.NETSUITE;
                    break;
                case EIntegrationType.Xero:
                    platform = EPlatformOpen.XERO;
                    break;
                case EIntegrationType.Dynamics365:
                    platform = EPlatformOpen.DYNAMICS365;
                    break;
                default:
                    break;
            }

            open({ platform });
        }

        if (integration.configType === EConfigurationType.QBO) {
            UserAuthenticationProvider.getAuthenticateStrategy()
                ?.getAccessToken().then((arApiToken) => connectToQBO(supplierLoginData.supplier_id, arApiToken, document.location.href)
                    .then((data: GetOAuthConnectURLResponse) => {
                        // Redirect to Intuit website for login
                        const redirectURL: string = data.qb_url;

                        document.location.href = redirectURL;
                    }));
        }
    };

    const isActive = (availableIntegration: AvailableIntegration): boolean => {
        if (activeIntegration?.isConnected && activeIntegration.type === availableIntegration?.configType) {
            if (availableIntegration?.configType === EConfigurationType.ArRutter) {
                // For Rutter we need to also check the name
                if (availableIntegration?.integrationType === activeIntegration.name) {
                    return true;
                }

                // If name doesn't match then return false
            } else {
                // For non-rutter configs the type check is enough
                return true;
            }
        }

        return false;
    };

    const availableIntegrations: AvailableIntegrations = [
        {
            logo: <img src={FidelioLogo} />,
            integrationType: EIntegrationType.Fidelio,
            name: 'Fidelio',
            desc: 'Receive invoices from Fidelio and send invoice\npayment to Fidelio once an invoice has been paid via\nNotch',
            openModal: true,
            configType: EConfigurationType.Fidelio,
            enabled: true,
        },
        {
            logo: <NetSuiteLogo />,
            integrationType: EIntegrationType.NetSuite,
            name: 'NetSuite',
            desc: 'Receive invoices from NetSuite and send invoice\npayment to NetSuite once an invoice has been paid\nvia Notch',
            configType: EConfigurationType.ArRutter,
            enabled: true,
        },
        {
            logo: <QuickbooksLogo/>,
            integrationType: EIntegrationType.QBO,
            name: 'Quickbooks Online',
            desc: 'Receive invoices from Quickbooks Online and send\ninvoice payment to Quickbooks Online once an\ninvoice has been paid via Notch',
            configType: flags.qboRutterOrNative === EQBOIntegrationType.NATIVE ? EConfigurationType.QBO : EConfigurationType.ArRutter,
            enabled: flags.qboIntegrationType === EQBOIntegrationType.REQUIRESLOGIN
        },
        ...(flags.xeroRutterEnabled ? [{
            logo: <XeroLogo />,
            integrationType: EIntegrationType.Xero,
            name: 'Xero',
            desc: 'Receive invoices from Xero and send invoice\npayment to Xero once an invoice has been paid\nvia Notch',
            configType: EConfigurationType.ArRutter,
            enabled: true,
        }] : []),
        ...(flags.dynamics365RutterEnabled ? [{
            logo: <Dynamics365Logo />,
            integrationType: EIntegrationType.Dynamics365,
            name: 'Dynamics 365',
            desc: 'Receive invoices from Dynamics 365 and send invoice\npayment to Dynamics 365 once an invoice has been paid\nvia Notch',
            configType: EConfigurationType.ArRutter,
            enabled: true,
        }] : []),
        {
            logo: <img src={Sage300Logo} />,
            integrationType: EIntegrationType.Sage300,
            name: 'Sage 300',
            desc: 'Receive invoices from Sage300 and send invoice\npayment to Sage300 once an invoice has been\npaid via Notch',
            configType: EConfigurationType.Sage300,
            openModal: true,
            enabled: true
        },
        {
            logo: <SpireLogo/>,
            integrationType: EIntegrationType.Spire,
            name: 'Spire',
            desc: 'Receive invoices from Spire and send invoice\npayment to Spire once an invoice has been paid via\n Notch',
            configType: EConfigurationType.Spire,
            openModal: true,
            enabled: true,
        },
    ];

    return (
        <div className="px-10 py-10 grid lg:grid-cols-3 gap-x-10 gap-y-8">
            {availableIntegrations.map((availableIntegration) => (
                <div key={availableIntegration.name} >
                    <div className="flex flex-col justify-between border-gray-200 gap-7 h-full">
                        <div>
                            <div className="w-8 h-8 flex justify-center items-center rounded-full border border-gray-200 mb-4">
                                {availableIntegration.logo}
                            </div>
                            <Typography weight="font-semibold" className="mb-1">
                                {availableIntegration.name}
                            </Typography>
                            <Typography className="text-gray-600">
                                {availableIntegration.desc}
                            </Typography>
                        </div>
                        <div className="mt-auto">
                            <Button size="SMALL"
                                className="mb-6"
                                disabled={!availableIntegration.enabled || (hasActiveIntegrationNotWorkato && !isActive(availableIntegration))}
                                onClick={(): void => onClickAccountingConnect(availableIntegration, isActive(availableIntegration))}>
                                {isActive(availableIntegration) ? 'Manage' : 'Connect'}
                            </Button>
                            <Separator/>
                        </div>

                    </div>
                </div>
            ))}
        </div>
    );
};
