import React, { useEffect } from 'react';
import { Button,
    Modal,
    Select,
    toast,
    Typography } from '@notch-ordering/ui-components';
import { IntegrationModals, useIntegrationsContext } from '@ar/pages/IntegrationsPage/IntegrationsContext';
import { AccountType, deleteIntegrationConfiguration,
    EConfigurationType,
    ERutterPlatform,
    updateIntegrationConfiguration } from '@ar/network/Bushwhack.network';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY, useGetAccountingGlAccounts } from '@ar/hooks/queries/IntegrationsQuery.hook';
import CheckIcon from '@icons/check-icon.svg';
import { IntegrationDisconnectConfirmModal } from '@ar/components/Integrations/IntegrationModals/IntegrationDisconnectConfirmModal';
import { IntegrationsTabs } from '@ar/pages/IntegrationsPage/IntegrationsPageConstants';
import { getIntegrationTypeForRoutePath, getPaymentAccountKey } from '@/ar/pages/IntegrationsPage/IntegrationUtils';

export interface AccountingArModalProps {
    integrationTypeForModal: IntegrationModals,
    integrationName: string,
}

export const AccountingArModal : React.FC<AccountingArModalProps> = ({ integrationTypeForModal, integrationName }) => {
    const { modals, setModal, supplierIntegrationConfiguration, isIntegrationSyncComplete, setIntegrationsTab, removeSupplierIntegration } = useIntegrationsContext();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();

    const integrationConfiguration = supplierIntegrationConfiguration?.find((integration) => integration.ownerID === supplierLoginData.supplier_id);

    const paymentAccountKey = getPaymentAccountKey(integrationConfiguration?.type);

    const [paymentAccountIDValue, setPaymentAccountIDValue] = React.useState<string>(integrationConfiguration?.data[paymentAccountKey]);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = React.useState<boolean>(false);

    const isQboRutter = integrationConfiguration?.type === EConfigurationType.ArRutter && integrationConfiguration?.data?.platform === ERutterPlatform.QUICKBOOKS;
    const isXeroRutter = integrationConfiguration?.type === EConfigurationType.ArRutter && integrationConfiguration?.data?.platform === ERutterPlatform.XERO;
    const isDynamics365Rutter = integrationConfiguration?.type === EConfigurationType.ArRutter && integrationConfiguration?.data?.platform === ERutterPlatform.DYNAMICS365;
    const isQboNative = integrationConfiguration?.type === EConfigurationType.QBO;

    let filterByAccountType: Set<AccountType>;
    if (isQboRutter || isQboNative || isDynamics365Rutter) {
        filterByAccountType = new Set();
        filterByAccountType.add('bank');
        filterByAccountType.add('other_current_asset');
        filterByAccountType.add('fixed_asset');
    } else if (isXeroRutter) {
        filterByAccountType = new Set();
        // Xero seems to only allow depositing funds into a payment location with account type bank
        filterByAccountType.add('bank');
    }

    const integrationType = getIntegrationTypeForRoutePath(integrationConfiguration?.type);
    const { data: accounts, isLoading: isLoadingAccounts } = useGetAccountingGlAccounts(supplierLoginData.supplier_id, integrationType, isIntegrationSyncComplete, filterByAccountType);
    const accountOptions = isLoadingAccounts ? [] : accounts?.accounts?.map((account) => ({
        label: account.name,
        value: account.id,
    }));

    const cleanUp = ():void => {
        queryClient.invalidateQueries([FETCH_SUPPLIER_CONFIGURATION_QUERY_KEY]);
        setPaymentAccountIDValue(integrationConfiguration?.data[paymentAccountKey]);
        setModal(integrationTypeForModal, false);
    };

    const { mutate: mutateDelete } = useMutation(deleteIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration disconnected successfully',
                icon: <CheckIcon/>,
            });
            cleanUp();
            removeSupplierIntegration();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });

    const { mutate: mutateUpdate, isLoading: isLoadingMutation } = useMutation(updateIntegrationConfiguration, {
        onSuccess: () => {
            toast.show({
                message: 'Integration updated successfully',
                icon: <CheckIcon/>,
            });
            cleanUp();
            setIntegrationsTab(IntegrationsTabs.Active);
        },
    });

    const handleOnClose = (): void => {
        setModal(integrationTypeForModal, false);
        cleanUp();
    };

    const handleDisconnect = (): void => {
        if (integrationConfiguration) {
            mutateDelete(integrationConfiguration?.id);
        }
        setIsConfirmDeleteModalOpen(false);
    };

    const handleUpdate = (): void => {
        mutateUpdate({
            ...integrationConfiguration,
            data: { ...integrationConfiguration?.data,
                [paymentAccountKey]: paymentAccountIDValue, },
        });
    };

    useEffect(() => {
        if (integrationConfiguration) {
            setPaymentAccountIDValue(integrationConfiguration.data[paymentAccountKey]);
        }
    }, [integrationConfiguration]);

    return <><Modal isOpen={modals.netsuite || modals.qbo || modals.xero}
        title={
            <><Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="XL" >
                Manage integration
            </Typography>
            <Typography variant="LG-1"
                weight="font-regular"
                className="text-gray-600" >
                {integrationName}
            </Typography>
            </>
        }
        headerPadding="mt-0 mb-5 px-3"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL">
        <div className="p-8">
            {isIntegrationSyncComplete === false
                ? <div className="flex flex-col items-left w-full">
                    <Typography className="m-0 pb-5 text-red-300"
                        weight="font-medium">
                        {`Please wait until the initial sync of ${integrationName} is complete before updating the integration settings.`}</Typography>
                </div>
                : <div className="space-y-4" >
                    <Typography className="font-semibold p-0 m-0">
                        Payment location
                    </Typography>
                    <Select
                        value={paymentAccountIDValue}
                        onChange={(e):void => setPaymentAccountIDValue(e.target.value)}
                        options={accountOptions}
                        placeholder="Select"
                        disabled={isLoadingAccounts}/>
                </div>
            }
        </div>
        <div className="pt-5 px-8 flex  gap-3">
            {integrationConfiguration && <Button variant="DESTRUCTIVE"
                size="SMALL"
                onClick={():void => setIsConfirmDeleteModalOpen(true)}>
                    Disconnect
            </Button>}
            <div className="flex gap-3 items-center ml-auto">
                <Button variant="TERTIARY_FILLED"
                    size="SMALL"
                    onClick={handleOnClose}>
                        Cancel
                </Button>
                <Button variant="SECONDARY"
                    disabled={isLoadingMutation || isLoadingAccounts}
                    loading={isLoadingMutation}
                    size="SMALL"
                    onClick={handleUpdate}>
                        Save
                </Button>
            </div>
        </div>
    </Modal>
    <IntegrationDisconnectConfirmModal integrationName={integrationName} onConfirm={handleDisconnect} isOpen={isConfirmDeleteModalOpen} onClose={(): void => setIsConfirmDeleteModalOpen(false)}/>
    </>;
};
