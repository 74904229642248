import { EIntegrationType } from '@/ar/network/AccountsReceivable.network';
import { EConfigurationType,
    ERutterPlatform,
    QBOConfigurationData,
    RutterConfigurationData,
    RutterConnectionStatus,
    SupplierConfiguration } from '@/ar/network/Bushwhack.network';

/**
 * Set the configuration type from the supplier name.
 * This is used for setting configuration type for integrations that have no configuration in Bushwhack (eg. QBO Workato)
 */
export const getConfigTypeFromSupplierIntegrationName = (supplierIntegrationName: EIntegrationType): EConfigurationType | null => {
    let integrationType: EConfigurationType | null;
    switch (supplierIntegrationName) {
        case EIntegrationType.QBOWORKATO:
            integrationType = EConfigurationType.Workato;
            break;
        case EIntegrationType.QBO:
        case EIntegrationType.NetSuite:
        case EIntegrationType.Xero:
        case EIntegrationType.Dynamics365:
            integrationType = EConfigurationType.ArRutter;
            break;
        case EIntegrationType.Fidelio:
            integrationType = EConfigurationType.Fidelio;
            break;
        case EIntegrationType.Spire:
            integrationType = EConfigurationType.Spire;
            break;
        case EIntegrationType.Sage300:
            integrationType = EConfigurationType.Sage300;
            break;
        case EIntegrationType.CsvImport: // fall-through
        case EIntegrationType.PdfImport:
        case EIntegrationType.QBD:
        default:
            integrationType = null;
            break;
    }

    return integrationType;
};

/**
 * A Notch handeled integration is an integration that has a configuration in Bushwhack.
 * Returns false if an integration is not handled by Notch (eg. QBO Workato)
 */
export const isNotchedHandledIntegration = (supplierIntegrationName: EIntegrationType): boolean => {
    switch (supplierIntegrationName) {
        case EIntegrationType.QBO:
        case EIntegrationType.NetSuite:
        case EIntegrationType.Fidelio:
        case EIntegrationType.Spire:
        case EIntegrationType.Sage300:
        case EIntegrationType.Xero:
        case EIntegrationType.Dynamics365:
            return true;
        case EIntegrationType.CsvImport: // fall-through
        case EIntegrationType.PdfImport:
        case EIntegrationType.QBD:
        case EIntegrationType.QBOWORKATO:
        default:
            return false;
    }
};

/**
 * Returns true if the platform provided is a Rutter platform
 */
export function isRutterPlatform(platform: ERutterPlatform): boolean {
    switch (platform) {
        case ERutterPlatform.NETSUITE:
        case ERutterPlatform.QUICKBOOKS:
        case ERutterPlatform.XERO:
        case ERutterPlatform.DYNAMICS365:
            return true;
        default:
            return false;
    }
}

/**
 * If the configuration type is Rutter or QBO native the initial sync time could take time (e.g an hour for Rutter)
 * However for other integrations we assume they are ready on connection (e.g Fidelio, Sage 300, QBO Workato)
 */
export function isIntegrationReady(type: EConfigurationType, isPlatformReady: boolean): boolean {
    if (type === EConfigurationType.ArRutter || type === EConfigurationType.QBO) {
        return isPlatformReady;
    }

    return true;
}

/**
 * Payment account ID have different names for Rutter and QBO native
 */
export function getPaymentAccountKey(integrationType: EConfigurationType): string {
    switch (integrationType) {
        case EConfigurationType.ArRutter:
            return 'rutterPaymentAccountID';
        case EConfigurationType.QBO:
            return 'paymentAccountID';
        default:
            return undefined;
    }
}

/**
 * Converts rutter platform name to type EintegrationType
 */
export const integrationNameRutter = (connectedIntegrationPlatform: string):string => {
    switch (connectedIntegrationPlatform) {
        case ERutterPlatform.NETSUITE:
            return EIntegrationType.NetSuite;
        case ERutterPlatform.QUICKBOOKS:
            return EIntegrationType.QBO;
        case ERutterPlatform.XERO:
            return EIntegrationType.Xero;
        case ERutterPlatform.DYNAMICS365:
            return EIntegrationType.Dynamics365;
        default:
            return null;
    }
};

/**
 * Returns true if configuration type is Rutter
 */
export function isRutterConfigurationData(data: SupplierConfiguration): boolean {
    return data?.type === EConfigurationType.ArRutter;
}

/**
 * Returns true if configuration type is QBO native
 */
export function isQBOConfigurationData(data: SupplierConfiguration): boolean {
    return data?.type === EConfigurationType.QBO;
}

/**
 * Get access token depending on if the integration is Rutter or QBO native
 */
export const getAccessToken = (config: SupplierConfiguration): string => {
    let accessToken: string;
    if (isRutterConfigurationData(config)) {
        accessToken = (config.data as RutterConfigurationData)?.rutterAccessToken;
    } else if (isQBOConfigurationData(config)) {
        accessToken = (config.data as QBOConfigurationData)?.auth?.accessToken;
    } else {
        accessToken = undefined;
    }

    return accessToken;
};

/**
 * The last sync completed is stored in different calls depending on if the integration is Rutter or QBO native
 */
export const getLastSyncAt = (config: SupplierConfiguration, rutterConnectionStatus: RutterConnectionStatus): string => {
    let lastSyncAt: string;
    if (isRutterConfigurationData(config)) {
        lastSyncAt = rutterConnectionStatus?.lastSyncCompletedAt;
    } else if (isQBOConfigurationData(config)) {
        lastSyncAt = (config.data as QBOConfigurationData)?.lastSyncCompletedAt;
    } else {
        lastSyncAt = undefined;
    }

    return lastSyncAt;
};

export type IntegrationTypePath = 'rutter' | 'qbo' | '';

/**
 * Takes config type and returns a string ready for addition to multiple end point route paths
 */
export function getIntegrationTypeForRoutePath(integrationType: EConfigurationType): IntegrationTypePath {
    switch (integrationType) {
        case EConfigurationType.ArRutter:
            return 'rutter';
        case EConfigurationType.QBO:
            return 'qbo';
        default:
            return '';
    }
}
