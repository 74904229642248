import InboxIcon from '@ar/assets/icons/inbox-icon.svg';
import { useApBillDeleteMutation, useApBillUpdateMutation } from '@ar/hooks/mutations/ApBillMutations';
import { ApBill, ApBillStatus } from '@ar/network/AccountsReceivable.network';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import CheckIcon from '@icons/check-icon.svg';
import DraftIcon from '@icons/draft-icon.svg';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { Button, Loading, Popover, PopoverItem, toast, Typography } from '@notch-ordering/ui-components';
import { APRoutePaths } from '@v2/constants/EPaths';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from '@/ar/components/shared/ConfirmModal';

type ApBillActionButtonProps = {
    bill: ApBill,

};

export const ApBillActionButton : React.FC<ApBillActionButtonProps> = ({ bill }) => {
    const apBillUpdateMutation = useApBillUpdateMutation();
    const apBillDeleteMutation = useApBillDeleteMutation();
    const { supplierLoginData } = useSupplierStore();
    const [isConfirmDeletionModalOpen, setIsConfirmDeletionModalOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleDeleteApBill = () => {
        apBillDeleteMutation.mutate({
            apBillID: bill.id,
            customerID: supplierLoginData?.customer_id,
        }, {
            onSuccess: async () => {
                toast.show({
                    message: 'Draft bill removed',
                    icon: <CheckIcon/>,
                });
                navigate(`/${APRoutePaths.AP_BILLS}`);
            },
            onError: () => {
                toast.show({
                    message: 'Failed to remove draft bill',
                });
            }
        });
    };
    const popOverItems:PopoverItem[] = [
        {
            label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                <div className="w-4"><DraftIcon className="w-4 h-4 mt-0.5 "/></div>
                <div className="flex flex-col truncate pr-5.5">
                    <div>Move back to Draft</div>
                </div>
            </Typography>,
            className: 'z-50',
            hidden: bill?.status !== ApBillStatus.Archived,
            onClick: (): void => {
                apBillUpdateMutation.mutate({
                    body: {
                        status: ApBillStatus.Draft
                    },
                    apBillID: bill.id,
                    customerID: supplierLoginData?.customer_id,
                }, {
                    onSuccess: async () => {
                        toast.show({
                            message: 'Draft bill moved back to draft',
                            icon: <CheckIcon/>,
                        });
                    },
                    onError: () => {
                        toast.show({
                            message: 'Failed to move draft bill back to draft',
                        });
                    }
                });
            },
        },
        {
            label: <Typography as="div" className="flex flex-nowrap gap-3 items-start  m-0 ">
                <div className="w-4"><InboxIcon className="w-4 h-4 mt-0.5 "/></div>
                <div className="flex flex-col truncate pr-5.5">
                    <div>Archive</div>
                </div>
            </Typography>,
            className: 'z-50',
            hidden: bill?.status === ApBillStatus.Archived || bill?.status === ApBillStatus.Published,
            onClick: (): void => {
                apBillUpdateMutation.mutate({
                    body: {
                        status: ApBillStatus.Archived
                    },
                    apBillID: bill.id,
                    customerID: supplierLoginData?.customer_id,
                }, {
                    onSuccess: async () => {
                        toast.show({
                            message: 'Draft bill archived',
                            icon: <CheckIcon/>,
                        });
                    },
                    onError: () => {
                        toast.show({
                            message: 'Failed to archive draft bill',
                        });
                    }
                });
            },
        },
        {
            label: <div className="flex text-red-500 gap-3">
                {apBillDeleteMutation.isLoading ? <Loading isDark/> : <TrashIcon className="w-4 h-4 mt-0.5 fill-red-500"/>}
                <Typography className="flex flex-nowrap gap-3 items-start  m-0 text-red-500">Remove</Typography>
            </div>,
            stopPropagation: true,
            // TODO: enable this after mvp release
            hidden: true,
            onClick: (): void => {
                setIsConfirmDeletionModalOpen(true);
            }
        },
    ];
    if (popOverItems.every((item) => item.hidden)) {
        return null;
    }

    return <><Popover
        className="w-auto"
        button=
            {<Button
                variant="TERTIARY_TABLE"
                className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                size="SMALL"
                stopPropagation={false}>
                <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                    <EllipsisIcon className="h-4 w-4"/>
                </div>
            </Button>}
        items={popOverItems}/>
    <ConfirmModal isOpen={isConfirmDeletionModalOpen}
        onConfirm={handleDeleteApBill}
        isLoading={apBillDeleteMutation.isLoading}
        onClose={() => setIsConfirmDeletionModalOpen(false)}
        prompt="Are you sure you want to remove this draft bill?"
        title="Remove Bill"
        confirmVariant="DESTRUCTIVE"/>
    </>;
};
