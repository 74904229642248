import { ApBill,
    ApBillStatus,
    getApBill,
    GetApBillIDParams,
    getApBills,
    GetApBillsParams,
    GetApBillsResponse } from '@ar/network/AccountsReceivable.network';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

export const FETCH_AP_BILLS_QUERY_KEY = 'FETCH_AP_BILLS_QUERY_KEY';

export type TUseGetApBillsQuery = {
    searchParams: GetApBillsParams,
};

const DEFAULT_REFETCH_INTERVAL_ON_PROCESSING = 5000;

export const useGetApBills = ({ searchParams }: TUseGetApBillsQuery): UseQueryResult<GetApBillsResponse> => useQuery(
    [FETCH_AP_BILLS_QUERY_KEY, searchParams],
    async () => getApBills(searchParams),
    { enabled: !!searchParams.customer_id.length,
        refetchInterval: (data) => {
            // If there are processing bills, refetch every 7 seconds
            const hasProcessingBills = data?.bills?.some((bill) => bill.status === ApBillStatus.Processing) ?? false;
            if (hasProcessingBills) {
                return DEFAULT_REFETCH_INTERVAL_ON_PROCESSING;
            }
            return false;
        } }
);

export const AP_BILL_QUERY_KEY = 'AP_BILL_QUERY_KEY';
export const useGetApBill = (params: GetApBillIDParams, options?: UseQueryOptions<ApBill>): UseQueryResult<ApBill> => useQuery(
    [AP_BILL_QUERY_KEY, params],
    async () => getApBill(params),
    {
        enabled: !!params.apBillID && !!params.customerID,
        refetchInterval: (data) => {
            // If the bill is processing, refetch every 7 seconds
            if (data?.status === ApBillStatus.Processing) {
                return DEFAULT_REFETCH_INTERVAL_ON_PROCESSING;
            }
            return false;
        },

        ...options
    }
);
