import React from 'react';
import DangerIcon from '@icons/danger-icon.svg';
import { toast } from '@notch-ordering/ui-components';
import CheckIcon from '@icons/check-icon.svg';
import { ConfirmModal } from '../shared/ConfirmModal';
import { ApBillModals, useApBillStore } from '@/ar/stores/ApBillStore';

export type ApBillItemDeleteModalProps = {
    onConfirmDelete: (id: string) => void,
};

export const ApBillItemDeleteModal : React.FC<ApBillItemDeleteModalProps> = ({ onConfirmDelete }) => {
    const { modals, closeModal } = useApBillStore();
    const { selectedItem, deleteItemModal } = modals;

    const onConfirm = () => {
        onConfirmDelete(selectedItem?.id);
        closeModal(ApBillModals.DeleteItem);
        toast.show({
            message: 'Product deleted',
            icon: <CheckIcon />,
            showClose: false,
        });
    };
    const onClose = () => {
        closeModal(ApBillModals.DeleteItem);
    };
    if (!selectedItem) return null;
    return <ConfirmModal isOpen={deleteItemModal}
        title={<div className="flex flex-col gap-4">
            <DangerIcon className="w-8 h-8 text-red-500" />
            <div>Are you sure you want to delete {selectedItem?.description} product?</div>
        </div>}
        confirmVariant={'DESTRUCTIVE'}
        confirmLabel={'Delete'}
        description={'This action cannot be undone after saving the draft bill.'}
        onConfirm={onConfirm}
        onClose={onClose} />;
};
